import { RacvFooterComponent } from '../../components/racv-footer/racv-footer.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountrySearchComponent } from '../../components/homepage/country-search/country-search.component';
import { StateSelectComponent } from '../../components/state-select/state-select.component';
import { provideNativeDateAdapter } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';

import { CoverageComponent } from '../../components/homepage/coverage/coverage.component';
import { GetQuotePanelComponent } from '../../components/homepage/get-quote-panel/get-quote-panel.component';
import { CustomerReviewsComponent } from '../../components/homepage/customer-reviews/customer-reviews.component';
import { RacvCommonHeaderComponent } from '../../components/racv-common-header/racv-common-header.component';
import { environment } from '../../../environments/environment';

import moment from 'moment';
import { Subscription } from 'rxjs';
import { WordingService } from '../../services/wording.service';
import { AdobeAnalyticsService } from '../../services/adobe.analytics.service';
@Component({
  selector: 'app-search-country',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    CommonModule,
    FormsModule,
    CountrySearchComponent,
    StateSelectComponent,
    CalendarModule,
    OverlayPanelModule,
    TabViewModule,
    ButtonModule,
    RacvFooterComponent,
    CoverageComponent,
    GetQuotePanelComponent,
    CustomerReviewsComponent,
    RacvCommonHeaderComponent,
  ],
  templateUrl: './search-country.component.html',
  styleUrl: './search-country.component.scss',
})
export class SearchCountryComponent implements OnInit, OnDestroy {
  dates: moment.Moment[] = [];
  titleText: string = '';
  subtitleText: string = '';
  subscriptions: Subscription = new Subscription();
  autoClub: string = '';
  constructor(
    private wordingService: WordingService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe;
  }
  ngOnInit(): void {
    this.autoClub = environment.autoClub;
    this.loadWordings();
    this.adobeAnalyticsService.sendGetaQuoteFormEvent();
  }
  isSelected = (event: any) => {
    const date = event as moment.Moment;
    return this.dates.find((x) => {
      return moment(x).isSame(date);
    })
      ? 'dateSelected'
      : '';
  };

  select(event: any, calendar: any) {
    const date: moment.Moment = event;
    const index = this.dates.findIndex((x) => moment(x).isSame(date));
    if (index < 0) this.dates.push(date);
    else this.dates.splice(index, 1);
    console.log('length', this.dates.length);
    calendar.updateTodaysDate();
  }

  loadWordings() {
    const validationServiceSub = this.wordingService
      .getWordings()
      .subscribe((response) => {
        response.forEach((wording: any) => {
          if (wording.field === 'homePageTitle') {
            this.titleText = wording.value;
          }
          if (wording.field === 'homePageSubtitle') {
            this.subtitleText = wording.value;
          }
        });
      });

    this.subscriptions.add(validationServiceSub);
  }
}
