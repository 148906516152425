import {
  AfterViewInit,
  ElementRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Router } from '@angular/router';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MessagesModule } from 'primeng/messages';
import { ValidationService } from '../../services/validation.service';
import { LocalCacheService } from '../../services/localCache.service';
import { QuoteService } from '../../services/quote.service';
import { MembershipService } from '../../services/membership.service';
import { Observable, Subject, Subscription, first, of, takeUntil } from 'rxjs';
import { Rule } from '../../interfaces/rule';
import { MedicalPremium } from '../../interfaces/medical';
import { CommonModule, Location } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { RacvHeaderComponent } from '../../components/racv-header/racv-header.component';
import { RacvFooterComponent } from '../../components/racv-footer/racv-footer.component';
import { FooterPriceBreakdownComponent } from '../../components/footer-price-breakdown/footer-price-breakdown.component';
import { VeriskWidgetComponent } from '../../components/verisk-widget/verisk-widget.component';
import { NgDialogAnimationService } from '../../services/dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MedicalService } from '../../services/medical.service';
import { StepBarComponent } from '../../components/step-bar/step-bar.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AddressifyService } from '../../services/addressify.service';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { questionnairesList } from '../../utils/questionnaires';
import xml2js from 'xml2js';
import { AgePipe } from '../../utils/age.pipe';
import { stateList } from '../../utils/stateList';
import { GlobalService } from '../../services/global.service';
import { Offcanvas } from 'bootstrap';
import { PDSService } from '../../services/pds.service';
import { KeyFilterModule } from 'primeng/keyfilter';
import { PricingService } from '../../services/pricing.service';
import { EmailQuoteComponent } from '../../components/email-quote/email-quote.component';
import {
  SubmissionStatus,
  TravelerFormValidator,
  TravelerValidatorService,
} from '../../services/traveler-validator.service';
import { customEmailValidator } from '../../services/email.validator';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressBarModule } from 'primeng/progressbar';
import { SegmentService } from '../../services/segment.service';
import { AdobeAnalyticsService } from '../../services/adobe.analytics.service';
@Component({
  selector: 'app-step-two-traveler-details',
  standalone: true,
  imports: [
    CommonModule,
    RacvFooterComponent,
    ReactiveFormsModule,
    MessagesModule,
    InputTextModule,
    RacvHeaderComponent,
    FooterPriceBreakdownComponent,
    MatDialogModule,
    StepBarComponent,
    DialogModule,
    ButtonModule,
    AutoCompleteModule,
    CalendarModule,
    ToastModule,
    ConfirmDialogModule,
    KeyFilterModule,
    EmailQuoteComponent,
    TooltipModule,
    ProgressBarModule,
  ],
  providers: [
    NgDialogAnimationService,
    MessageService,
    ConfirmationService,
    AgePipe,
  ],
  templateUrl: './step-two-traveler-details.component.html',
  styleUrl: './step-two-traveler-details.component.scss',
})
export class StepTwoTravelerDetailsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('collapseTrigger') collapseTrigger!: ElementRef;
  private subscriptions: Subscription = new Subscription();
  private destroySub$ = new Subject<void>();
  travelerDetailsForm: FormGroup = new FormGroup({});
  medicalDeclarationForm: FormGroup = new FormGroup({});
  addressSuggestions: string[] = [];
  medicalConditions: any[] = [];
  questionnairesList: any[] = [];
  validationsList: any[] = [];
  showAcceptMedicalAssessmentModal: boolean = false;
  showMedAssessVeriskModal: boolean = false;
  medConditionSelected: string = '';
  adult1MedConSelected: boolean = false;
  adult2MedConSelected: boolean = false;
  hasEmergencyContact: boolean = false;
  isAddressValid: boolean = false;
  hasDependent: boolean = false;
  isEnableForm: boolean = false;
  selectedCover: any;
  quoteDetail: any;
  medicalPremium: any;
  basicPrice: any = {};
  essentialPrice: any = {};
  showReDoAssessmentModal: boolean = false;
  quoteEnquiry: any;
  dobMinDate: Date = new Date('1900-01-01');
  dobMaxDate: Date = new Date();
  activatedClub: string = '';
  errorMessages: any = {};
  selectedAssessmentDetails: any;
  hasAdult2: boolean = false;
  primaryHasAssessment: boolean = false;
  primaryAssessmentStatus: string = '';
  secondaryHasAssessment: boolean = false;
  secondaryAssessmentStatus: string = '';
  primaryReassessment: boolean = false;
  secondaryReassessment: boolean = false;
  isAdult1AgeValid: boolean = true;
  isAdult2AgeValid: boolean = true;
  linkedCondition: any;
  currentAssessmentName: string = '';
  offcanvas: any;
  showMemebershipNumber: boolean = false;
  isInitialAssessment: boolean = false;

  firstMinAge: number = 0;
  firstMaxAge: number = 0;
  secondMinAge: number = 0;
  secondMaxAge: number = 0;
  showReassessAlert: boolean = false;

  selectedTabIndex: number = 0;
  selectedTripValue!: string;
  selectedTripLabel!: string;
  topPrice: any = {};
  annualPrice: any = {};
  domesticPrice: any = {};
  pricingDetail: any[] = [];
  isDomestic: boolean = false;
  tabs: any[] = [];
  isMostPopular: boolean = false;
  isTier1: boolean = false;
  isTier2: boolean = false;
  isTier3: boolean = false;
  currentTier!: string;
  discountedPriceLabel: string = '';
  isCruiseCountry: any;
  selectedTripLenghtValue!: string;
  selectedTripLenghtLabel!: string;
  membershipNoMaxLength: number = 0;
  isQuestionnaireContinue: boolean = false;
  adult1MedicalRequired: boolean = false;
  adult2MedicalRequired: boolean = false;
  depMedicalRequired: any = [];
  isLoadingAddressify: boolean = false;

  isAdult1AgeValidOnSubmit: boolean = true;
  isAdult2AgeValidOnSubmit: boolean = true;
  constructor(
    private router: Router,
    private validationService: ValidationService,
    private localCacheService: LocalCacheService,
    private quoteService: QuoteService,
    private formBuilder: FormBuilder,
    private dialog: NgDialogAnimationService,
    private renderer: Renderer2,
    private el: ElementRef,
    private medicalService: MedicalService,
    private addressifyService: AddressifyService,
    private messageService: MessageService,
    private globalService: GlobalService,
    private agePipe: AgePipe,
    private confirmationService: ConfirmationService,
    private pdsService: PDSService,
    private membershipService: MembershipService,
    private pricingService: PricingService,
    private travelerValidatorService: TravelerValidatorService,
    private location: Location,
    private segmentService: SegmentService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  @ViewChild('saveAndSendEmail')
  saveAndSendEmail!: EmailQuoteComponent;
  submission: SubmissionStatus = {
    Status: false,
    Email: '',
  };

  openSaveAndSendModal() {
    if (
      this.isTravelerControlInvalid('adult1') ||
      this.isTravelerControlInvalid('adult2') ||
      this.isTravelerControlInvalid('dependents')
    ) {
      this.saveAndSendEmail?.openModal(true);
    } else {
      this.saveAndSendEmail?.openModal(false);
    }
  }

  ngOnInit(): void {
    if (!this.globalService.checkAndValidatePage(2, false)) {
      this.router.navigate(['/error']);
      return;
    }
    this.activatedClub = environment.autoClub; //TO DO: CHANGE TO DYNAMIC HANDLING
    this.selectedCover =
      this.localCacheService.getSessionStorage('selectedCoverDetail') || {};

    this.quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry') || {};
    this.quoteDetail =
      this.localCacheService.getSessionStorage('quoteDetail') || {};
    if (!this.quoteDetail || Object.keys(this.quoteDetail).length === 0) {
      this.router.navigate(['/']);
      return;
    }
    // for user refresh page during any medical assessment process, get latest quote detail
    this.quoteService
      .getQuoteByQuoteId(this.quoteDetail.quoteId)
      .subscribe((response) => {
        this.getClubQuestionnaire(this.activatedClub);

        this.medicalPremium =
          this.localCacheService.getSessionStorage('medicalPremium') || {};

        if (!response.error) {
          this.localCacheService.saveSessionStorage('quoteDetail', response);
          this.quoteDetail = response;
          this.setTravelerDetails(response);
          this.hasAdult2 =
            this.quoteDetail.ages?.length > 1 && this.quoteDetail.ages[1] > 0
              ? true
              : false;
          this.hasDependent = Number(this.quoteDetail.dependentsCount) > 0;
        }
        this.loadValidations();
        this.createForm();
        this.checkingReasessment();
      });

    this.showMemebershipNumber =
      typeof this.quoteEnquiry.isClubMember === 'boolean'
        ? this.quoteEnquiry.isClubMember
        : this.quoteEnquiry.isClubMember === 'true';

    this.subscriptions.add(
      this.travelerValidatorService.validateTriggerForm$.subscribe(() => {
        var validFormObj: TravelerFormValidator = {
          IsValid: true,
        };
        if (
          this.isTravelerControlInvalid('adult1') ||
          this.isTravelerControlInvalid('adult2') ||
          this.isTravelerControlInvalid('dependents')
        ) {
          validFormObj.IsValid = false;
        }
        this.travelerValidatorService.validateForm(validFormObj);
      }),
    );

    this.subscriptions.add(
      this.travelerValidatorService.triggerSubmittionForm$.subscribe(
        (submission: SubmissionStatus) => {
          if (
            !this.isTravelerControlInvalid('dependents', true) &&
            !this.isTravelerControlInvalid('adult1', true) &&
            !this.isTravelerControlInvalid('adult2', true)
          ) {
            this.submission = submission;
            this.submitTravelerDetails(submission);
          } else if (
            !this.travelerDetailsForm.valid &&
            !this.travelerDetailsForm.touched
          ) {
            submission.Status = true;
            this.travelerValidatorService.submitFormStatus(submission);
          }
        },
      ),
    );
    this.adobeAnalyticsService.sendEnterDetails();
  }

  isTravelerControlInvalid(groupName: string, skipDOBCheck = false): boolean {
    if (groupName == 'dependents') {
      for (
        let index = 0;
        index < this.travelerDetailsForm.value.dependents.length;
        index++
      ) {
        const dependentGroup = this.dependents.at(index) as FormGroup;
        const controlFirstName = dependentGroup.get('firstName');
        const controlLastName = dependentGroup.get('lastName');
        const controlDOB = dependentGroup.get('dateOfBirth');
        return this.isFormControlInValid(
          controlFirstName,
          controlLastName,
          controlDOB,
          skipDOBCheck,
        );
      }
    } else {
      const group = this.travelerDetailsForm.get(groupName) as FormGroup;
      if (group) {
        const controlFirstName = group?.get('firstName');
        const controlLastName = group?.get('lastName');
        const controlDOB = group?.get('dateOfBirth');
        return this.isFormControlInValid(
          controlFirstName,
          controlLastName,
          controlDOB,
          skipDOBCheck,
        );
      }
    }
    return false;
  }

  isFormControlInValid(
    controlFirstName: any,
    controlLastName: any,
    controlDOB: any,
    skipDOBCheck = false, // flag to skip DOB check on save and send email
  ): boolean {
    if (
      (controlFirstName?.touched &&
        (controlFirstName.value == '' || !controlFirstName.valid)) ||
      (controlLastName?.touched &&
        (controlLastName.value == '' || !controlLastName.valid)) ||
      (controlDOB?.touched &&
        (controlDOB.value == '' || !controlDOB.valid) &&
        !skipDOBCheck)
    ) {
      controlFirstName.markAsTouched();
      controlLastName.markAsTouched();
      controlDOB.markAsTouched();
      return true;
    }
    return false;
  }

  ngAfterViewInit() {
    this.collapseTrigger.nativeElement.click();
    this.offcanvas = Offcanvas.getOrCreateInstance('#medical_btn');
  }

  ngOnDestroy(): void {
    this.destroySub$.next();
    this.destroySub$.complete();
    this.subscriptions.unsubscribe();
  }

  getClubQuestionnaire(site: string) {
    let transQuestionnairesList: any = questionnairesList.filter((data) =>
      data.hasOwnProperty(site),
    );
    this.questionnairesList = transQuestionnairesList.map(
      (data: any) => data[site],
    )[0];
  }

  getSpecificQuestion(id: any) {
    const questionnaire = this.questionnairesList.find(
      (item) => item.id === id,
    );
    if (questionnaire) {
      return questionnaire.question;
    } else {
      return null;
    }
  }

  checkingReasessment() {
    // refresh page call the submission api, so only rely on session storage
    if (this.quoteDetail?.travelers?.length > 0) {
      this.quoteDetail?.travelers.forEach((element: any) => {
        if (element.role === 'primary') {
          this.primaryHasAssessment =
            element?.medicalInfo?.xml?.length > 0 ? true : false;
          this.primaryAssessmentStatus =
            this.medicalService.getOfferStatusString(element?.medicalInfo);
          this.primaryReassessment =
            (element?.medicalInfo?.rescore &&
              element?.medicalInfo?.status !== 'Pending') ??
            false;
        } else if (element.role === 'secondary') {
          this.secondaryHasAssessment =
            element?.medicalInfo?.xml?.length > 0 ? true : false;
          this.secondaryAssessmentStatus =
            this.medicalService.getOfferStatusString(element?.medicalInfo);
          this.secondaryReassessment =
            (element?.medicalInfo?.rescore &&
              element?.medicalInfo?.status !== 'Pending') ??
            false;
        } else if (element.role === 'dependent') {
          this.dependents.controls.forEach((cont) => {
            if (
              //cont.value.medConSelected &&
              cont.value.firstName === element.firstName &&
              cont.value.lastName === element.lastName
            ) {
              cont
                .get('reassessment')
                ?.setValue(
                  (element?.medicalInfo?.rescore &&
                    element?.medicalInfo?.status !== 'Pending') ??
                    false,
                );
              cont
                .get('hasAssessment')
                ?.setValue(element?.medicalInfo?.status ? true : false);
              cont
                .get('assessmentStatus')
                ?.setValue(
                  this.medicalService.getOfferStatusString(
                    element?.medicalInfo,
                  ),
                );
            }
          });
        }
      });
    }
  }

  onAutocompleteAddress(event: any): void {
    this.isLoadingAddressify = true;
    this.addressSuggestions = [];
    let query = event.query;
    query = query.replaceAll('&', '%26');
    query = query + '&state=' + this.quoteEnquiry?.state?.code || '';
    this.addressifyService.autoComplete(query).subscribe((data) => {
      this.addressSuggestions = data;
      this.isLoadingAddressify = false;
    });
  }
  showInvalidAddress() {
    this.messageService.add({
      severity: 'error',
      summary: 'Address',
      detail: 'Address is invalid',
    });
  }
  selectAddress(event: any): void {
    this.addressifyService.info(event.value).subscribe((data) => {
      this.isAddressValid = data.Valid;
      if (
        !data.Valid &&
        !data.StreetLine &&
        !data.Suburb &&
        !data.State &&
        !data.Postcode
      ) {
        this.showInvalidAddress();
      }
      this.travelerDetailsForm.patchValue({
        contactAndAddressDetails: {
          address: '',
          street: data.StreetLine,
          subUrb: data.Suburb,
          state: data.State,
          postalCode: data.Postcode,
        },
      });
    });
  }

  reOpenMedicalDeclaration(): void {
    this.adult1MedicalRequired = false;
    this.adult1MedicalRequired = false;
    this.depMedicalRequired = [];
    this.showAcceptMedicalAssessmentModal = false;
    this.medicalDeclarationForm.patchValue({
      medicalTreatment: '',
      investigation: '',
      purchasePolicy: '',
      medicalCondition: '',
    });
    const role = this.localCacheService.getSessionStorage('currentAssessment');
    const dependentIndex =
      this.localCacheService.getSessionStorage('dependentIndex');
    this.selectedAssessmentDetails = this.quoteService.fetchTravelerBasedOnRole(
      this.travelerDetailsForm.value,
      role,
      dependentIndex,
    );
    this.isInitialAssessment = false;
    this.offcanvas.show();
  }

  openDialog(): void {
    // regardless if initial or not, validate the choices before proceeding to verisk
    this.medicalDeclaration().subscribe((response) => {
      if (response) {
        // this.isInitialAssessment = false;
        this.openVeriskDialog();
      }
    });
  }

  openVeriskDialog(): void {
    this.showMedAssessVeriskModal = true;
    const dialogRef = this.dialog.open(VeriskWidgetComponent, {
      direction: 'rtl',
      width: '100%',
      animation: { to: 'left' },
      position: { rowEnd: '0' },
    });
    this.toggleOverlayContainer();

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.isPopupClosed) {
        this.location.replaceState('/quotes-steps-two');
        this.showMedAssessVeriskModal = false;
        this.toggleOverlayContainer();
      } else {
        this.handleAfterClose();
      }
    });
  }

  handleAfterClose() {
    this.showMedAssessVeriskModal = false;
    this.currentAssessmentName = this.globalService.getCurrentAssessmentName();
    this.medicalPremium =
      this.localCacheService.getSessionStorage('medicalPremium');
    this.showAcceptMedicalAssessmentModal = true;
    this.medicalConditions = this.medicalPremium?.medicalConditions
      ? this.medicalPremium?.medicalConditions.split('|')
      : [];
    this.toggleOverlayContainer();
  }

  toggleOverlayContainer(): void {
    const overlayContainer = this.el.nativeElement.ownerDocument.querySelector(
      '.cdk-overlay-container',
    );
    if (this.showMedAssessVeriskModal) {
      this.renderer.setStyle(overlayContainer, 'display', 'block');
    } else {
      this.renderer.setStyle(overlayContainer, 'display', 'none');
    }
  }

  submitTravelerDetails(submissionStatusFromSaveAndSend: any = null) {
    this.medicalService.saveTravelerDetails(this.travelerDetailsForm.value);
    let newQuoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    newQuoteEnquiry.dependentsCount =
      this.travelerDetailsForm.value.dependents.length;
    this.localCacheService.saveSessionStorage('quoteEnquiry', newQuoteEnquiry);
    this.quoteService
      .submitQuote(
        this.travelerDetailsForm.value,
        submissionStatusFromSaveAndSend
          ? 'step-two-save-send'
          : 'step-two-traveler-detail',
      )
      .subscribe({
        next: (response) => {
          if (!response.error) {
            this.quoteDetail = response;
            this.localCacheService.saveSessionStorage('quoteDetail', response);
            this.checkingReasessment();
            if (!this.primaryReassessment) {
              if (submissionStatusFromSaveAndSend) {
                submissionStatusFromSaveAndSend.Status = true;
                this.travelerValidatorService.submitFormStatus(
                  submissionStatusFromSaveAndSend,
                );
              } else {
                this.globalService.checkAndValidatePage(2, true);
                this.router.navigate(['/quotes-steps-three']);
              }
            } else {
              this.showReassessAlert = true;
              if (submissionStatusFromSaveAndSend) {
                submissionStatusFromSaveAndSend.Status = false;
                this.travelerValidatorService.submitFormStatus(
                  submissionStatusFromSaveAndSend,
                );
              }
            }
          } else if (
            response.errorResponse &&
            response?.errorResponse.includes('Invalid Member')
          ) {
            let travelForm = document.getElementById('travelerDetails');
            let firstInvalidControl =
              travelForm?.querySelector('#medical_con_yes');
            if (firstInvalidControl) {
              firstInvalidControl.scrollIntoView({ behavior: 'smooth' });
            }
            this.showMembershipValidationPopup(response);
          }
        },
        error: (error) => {
          console.log('step-two-traveler-detail error', error);
          if (submissionStatusFromSaveAndSend) {
            submissionStatusFromSaveAndSend.Status = false;
            this.travelerValidatorService.submitFormStatus(
              submissionStatusFromSaveAndSend,
            );
          }
        },
      });
  }
  hideReassessAlert() {
    this.showReassessAlert = false;
  }

  get email() {
    const contactAndAddressDetailsFormGroup = this.travelerDetailsForm.get(
      'contactAndAddressDetails',
    ) as FormGroup;
    return contactAndAddressDetailsFormGroup.get('emailId');
  }
  get confEmailId() {
    const contactAndAddressDetailsFormGroup = this.travelerDetailsForm.get(
      'contactAndAddressDetails',
    ) as FormGroup;
    return contactAndAddressDetailsFormGroup.get('confEmailId');
  }

  createForm() {
    const travelerDetails =
      this.localCacheService.getSessionStorage('travelerDetails') || {};
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry') || {};

    let state = 'NSW';
    if (travelerDetails.contactAndAddressDetails?.state) {
      state = travelerDetails.contactAndAddressDetails?.state;
    } else if (quoteEnquiry.state) {
      state = quoteEnquiry.state.code;
    }
    this.travelerDetailsForm = this.formBuilder.group(
      {
        medical_con: new FormControl(travelerDetails.medical_con ?? ''),
        adult1: this.formBuilder.group({
          firstName: [
            { value: travelerDetails.adult1?.firstName ?? '', disabled: true },
            [
              this.medicalService.nameShouldNotExceed50AndNotContainNumbers(
                'Primary Traveller',
                'First Name',
              ),
            ],
          ],
          lastName: [
            { value: travelerDetails.adult1?.lastName ?? '', disabled: true },
            [
              this.medicalService.nameShouldNotExceed50AndNotContainNumbers(
                'Primary Traveller',
                'Last Name',
              ),
            ],
          ],
          dateOfBirth: [
            {
              value: travelerDetails.adult1?.dateOfBirth ?? '',
              disabled: true,
            },
            Validators.required,
          ],
          membershipNumber: [
            {
              value: travelerDetails.adult1?.membershipNumber ?? '',
              disabled: true,
            },
            this.quoteEnquiry.isClubMember === 'true' ||
            this.quoteEnquiry.isClubMember === true
              ? Validators.required
              : [],
          ],
          one_med_con: new FormControl(
            travelerDetails.adult1?.one_med_con ?? '',
            [this.shouldSelectYesOrNo()],
          ),
        }),
        adult2: this.formBuilder.group({
          firstName: [
            { value: travelerDetails.adult2?.firstName ?? '', disabled: true },
            [
              this.medicalService.nameShouldNotExceed50AndNotContainNumbers(
                'Secondary Traveller',
                'First Name',
              ),
            ],
          ],
          lastName: [
            { value: travelerDetails.adult2?.lastName ?? '', disabled: true },
            [
              this.medicalService.nameShouldNotExceed50AndNotContainNumbers(
                'Secondary Traveller',
                'Last Name',
              ),
            ],
          ],
          dateOfBirth: [
            {
              value: travelerDetails.adult2?.dateOfBirth ?? '',
              disabled: true,
            },
            Validators.required,
          ],
          two_med_con: new FormControl(
            travelerDetails.adult2?.two_med_con ?? '',
            [this.shouldSelectYesOrNo()],
          ),
        }),
        contactAndAddressDetails: this.formBuilder.group(
          {
            emailId: [
              {
                value: travelerDetails.contactAndAddressDetails?.emailId ?? '',
                disabled: true,
              },
              [Validators.required, customEmailValidator()],
            ],
            confEmailId: [
              {
                value: travelerDetails.contactAndAddressDetails?.emailId ?? '',
                disabled: true,
              },
              [Validators.required, customEmailValidator()],
            ],
            phoneNumber: [
              {
                value:
                  travelerDetails.contactAndAddressDetails?.phoneNumber ?? '',
                disabled: true,
              },
              Validators.required,
            ],
            address: [{ value: '', disabled: true }],
            street: [
              {
                value: travelerDetails.contactAndAddressDetails?.street ?? '',
                disabled: true,
              },
              [Validators.required],
            ],
            subUrb: [
              {
                value: travelerDetails.contactAndAddressDetails?.subUrb ?? '',
                disabled: true,
              },
              [Validators.required],
            ],
            state: [
              {
                value: state,
                disabled: true,
              },
            ],
            postalCode: [
              {
                value:
                  travelerDetails.contactAndAddressDetails?.postalCode ?? '',
                disabled: true,
              },
              Validators.required,
            ],
          },
          { validators: this.medicalService.emailMatchValidator },
        ),
        dependents: this.formBuilder.array([]),
        emergencyContact: this.formBuilder.group({
          firstName: [
            {
              value: travelerDetails.emergencyContact?.firstName ?? '',
              disabled: false,
            },
          ],
          lastName: [
            {
              value: travelerDetails.emergencyContact?.lastName ?? '',
              disabled: false,
            },
          ],
          emailId: [
            {
              value: travelerDetails.emergencyContact?.emailId ?? '',
              disabled: false,
            },
            [Validators.email],
          ],
          phoneNumber: [
            {
              value: travelerDetails.emergencyContact?.phoneNumber ?? '',
              disabled: false,
            },
            [Validators.maxLength(20)],
          ],
        }),
      },
      { validator: this.medicalService.checkTravellers },
    );

    this.medicalDeclarationForm = this.formBuilder.group({
      medicalTreatment: [{ value: '' }, [Validators.required]],
      investigation: [{ value: '' }, [Validators.required]],
      purchasePolicy: [{ value: '' }, [Validators.required]],
      medicalCondition: [{ value: '' }, [Validators.required]],
    });

    if (travelerDetails.medical_con) {
      this.enableForm();

      if (travelerDetails.medical_con == 'yes') {
        this.medConditionSelected = 'yes';
      } else {
        this.medConditionSelected = 'no';
      }
    }

    if (travelerDetails?.adult1?.one_med_con === 'yes') {
      this.adult1MedConSelected = true;
      this.travelerDetailsForm.get('adult1')?.markAllAsTouched();
    }
    if (travelerDetails?.adult2?.two_med_con === 'yes') {
      this.adult2MedConSelected = true;
    }

    this.showAdult2Section(this.hasAdult2);
    this.addDependent();
    this.checkMedicalConState();
  }

  checkMedicalConState() {
    this.travelerDetailsForm
      .get('medical_con')
      ?.valueChanges.pipe(takeUntil(this.destroySub$))
      .subscribe({
        next: (response) => {
          if (response) {
            this.medConditionSelected = response;
            if (!this.isEnableForm) {
              this.enableForm();
            }
            return;
          } else {
            console.log('medical_con response error');
          }
        },
        error: (error) => {
          console.log('medical_con subscription error', error);
        },
      });
    //test purpose for record form in session storage
    this.travelerDetailsForm.valueChanges
      .pipe(takeUntil(this.destroySub$))
      .subscribe({
        next: (response) => {
          this.medicalService.saveTravelerDetails(response);
        },
      });
    this.medicalDeclarationForm.valueChanges
      .pipe(takeUntil(this.destroySub$))
      .subscribe({
        next: (response) => {
          if (
            response.medicalTreatment === 'no' &&
            (response?.investigation === 'no' ||
              response?.investigation === 'yes') &&
            response?.medicalCondition === 'yes' &&
            (response?.purchasePolicy === 'no' ||
              response?.purchasePolicy === 'yes')
          ) {
            this.isQuestionnaireContinue = true;
          } else {
            this.isQuestionnaireContinue = false;
          }
        },
      });
  }

  enableForm() {
    this.isEnableForm = true;
    const adult1FormGroup = this.travelerDetailsForm.get('adult1') as FormGroup;
    adult1FormGroup.get('firstName')?.enable();
    adult1FormGroup.get('lastName')?.enable();
    adult1FormGroup.get('dateOfBirth')?.enable();
    adult1FormGroup.get('membershipNumber')?.enable();

    if (this.hasAdult2) {
      const adult2FormGroup = this.travelerDetailsForm.get(
        'adult2',
      ) as FormGroup;
      adult2FormGroup.get('firstName')?.enable();
      adult2FormGroup.get('lastName')?.enable();
      adult2FormGroup.get('dateOfBirth')?.enable();
    }

    const contactAndAddressDetailsFormGroup = this.travelerDetailsForm.get(
      'contactAndAddressDetails',
    ) as FormGroup;
    contactAndAddressDetailsFormGroup.get('emailId')?.enable();
    contactAndAddressDetailsFormGroup.get('confEmailId')?.enable();
    contactAndAddressDetailsFormGroup.get('phoneNumber')?.enable();
    contactAndAddressDetailsFormGroup.get('address')?.enable();
    contactAndAddressDetailsFormGroup.get('street')?.enable();
    contactAndAddressDetailsFormGroup.get('subUrb')?.enable();
    contactAndAddressDetailsFormGroup.get('state')?.enable();
    contactAndAddressDetailsFormGroup.get('postalCode')?.enable();
  }

  onStartMedicalAssesment(
    event: any,
    role: string,
    dependentIndex: number = -1,
  ) {
    event.preventDefault();
    this.medicalDeclarationForm.patchValue({
      medicalTreatment: '',
      investigation: '',
      purchasePolicy: '',
      medicalCondition: '',
    });
    this.selectedAssessmentDetails = null;
    let newQuoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    newQuoteEnquiry.dependentsCount =
      this.travelerDetailsForm.value.dependents.length;
    this.localCacheService.saveSessionStorage('quoteEnquiry', newQuoteEnquiry);
    this.quoteService
      .createTraveler(this.travelerDetailsForm.value, role, dependentIndex)
      .pipe(takeUntil(this.destroySub$))
      .subscribe({
        next: (response: any) => {
          if (
            !response.error &&
            (response.Success === undefined || response.success)
          ) {
            this.selectedAssessmentDetails =
              this.quoteService.fetchTravelerBasedOnRole(
                this.travelerDetailsForm.value,
                role,
                dependentIndex,
              );
            this.medicalService.saveTravelerDetails(
              this.travelerDetailsForm.value,
            );
            this.localCacheService.saveSessionStorage(
              'currentAssessment',
              role,
            );
            if (dependentIndex >= 0) {
              this.localCacheService.saveSessionStorage(
                'dependentIndex',
                dependentIndex,
              );
            }
            let currentAssessmentDetails =
              this.localCacheService.getSessionStorage('assessmentDetails') ??
              {};
            if (role === 'primary') {
              currentAssessmentDetails.primary = {
                transactionId: response.quoteId,
                role,
                travelerId: response.travelerId,
              };
              this.localCacheService.saveSessionStorage(
                'assessmentDetails',
                currentAssessmentDetails,
              );
            } else if (role === 'secondary') {
              currentAssessmentDetails.secondary = {
                transactionId: response.quoteId,
                role,
                travelerId: response.travelerId,
              };
              this.localCacheService.saveSessionStorage(
                'assessmentDetails',
                currentAssessmentDetails,
              );
            } else {
              // initial adding dependent no need to check find the index, and need to add traveler ID into taravelerDetails
              if (!currentAssessmentDetails.dependent) {
                currentAssessmentDetails.dependent = [];
              }
              const newDependent = {
                transactionId: response.quoteId,
                role,
                travelerId: response.travelerId,
              };
              currentAssessmentDetails.dependent.push(newDependent);
              this.localCacheService.saveSessionStorage(
                'assessmentDetails',
                currentAssessmentDetails,
              );
              let travelerDetails =
                this.localCacheService.getSessionStorage('travelerDetails');
              travelerDetails.dependents[dependentIndex].travelerId =
                response.travelerId;
              this.localCacheService.saveSessionStorage(
                'travelerDetails',
                travelerDetails,
              );
            }
            this.isInitialAssessment = true;
            this.offcanvas.show();
          } else {
            console.log(
              'submit quote on step two before assessment error',
              response,
            );
          }
        },
        error: (error: any) => {
          console.log('submitQuote subscription error', error);
        },
      });
  }
  //this is for initial loading adding dependents
  addDependent() {
    if (
      this.quoteDetail.travelers.find(
        (traveler: any) => traveler.role === 'dependent',
      )
    ) {
      let totalDependentCount = 0;
      let newTravelerDetails =
        this.localCacheService.getSessionStorage('travelerDetails');
      this.quoteDetail.travelers.map((traveler: any) => {
        if (traveler.role === 'dependent') {
          totalDependentCount++;
          const dependentsFormGroup = this.formBuilder.group({
            firstName: [
              { value: traveler.firstName, disabled: false },
              [
                this.medicalService.nameShouldNotExceed50AndNotContainNumbers(
                  'Dependent',
                  'First Name',
                ),
              ],
            ],
            lastName: [
              { value: traveler.lastName, disabled: false },
              [
                this.medicalService.nameShouldNotExceed50AndNotContainNumbers(
                  'Dependent',
                  'Last Name',
                ),
              ],
            ],
            dateOfBirth: [
              { value: traveler.dateOfBirth, disabled: false },
              [Validators.required, this.validateDependentBirthdate()],
            ],
            hasAssessment: [traveler.medicalInfo?.xml ? true : false],
            reassessment: [traveler.medicalInfo?.rescore ?? false],
            dependent_med_con: [
              newTravelerDetails.dependents.find(
                (dep: any) => dep.travelerId === traveler.travelerId,
              )?.dependent_med_con ?? '',
              [this.shouldSelectYesOrNo()],
            ],
            medConSelected: [
              newTravelerDetails.dependents.find(
                (dep: any) => dep.travelerId === traveler.travelerId,
              )?.medConSelected ?? false,
            ],
            assessmentStatus: [traveler.medicalInfo?.status ?? ''],
          });
          this.dependents.push(dependentsFormGroup);
        }
      });
      // Add remaining dependent control
      if (totalDependentCount < this.quoteDetail.dependentsCount) {
        let remainingDependentCount =
          this.quoteDetail.dependentsCount - totalDependentCount;
        for (let index = 0; index < remainingDependentCount; index++) {
          this.addNewDependent();
        }
      }
    } else if (this.quoteDetail.dependentsCount > 0) {
      //Render empty dependent based on home page dependent count
      for (let index = 0; index < this.quoteDetail.dependentsCount; index++) {
        this.addNewDependent();
      }
    }
  }

  addNewDependent() {
    const dependentsFormGroup = this.formBuilder.group({
      firstName: [
        { value: '', disabled: false },
        [
          this.medicalService.nameShouldNotExceed50AndNotContainNumbers(
            'Dependent',
            'First Name',
          ),
        ],
      ],
      lastName: [
        { value: '', disabled: false },
        [
          this.medicalService.nameShouldNotExceed50AndNotContainNumbers(
            'Dependent',
            'Last Name',
          ),
        ],
      ],
      dateOfBirth: [
        { value: '', disabled: false },
        [Validators.required, this.validateDependentBirthdate()],
      ],
      dependent_med_con: ['', [this.shouldSelectYesOrNo()]],
      hasAssessment: [false],
      reassessment: [false],

      medConSelected: [false],
      assessmentStatus: [''],
    });

    this.dependents.push(dependentsFormGroup);
  }

  get dependents(): FormArray {
    return this.travelerDetailsForm.get('dependents') as FormArray;
  }

  getDependentFormGroup(index: number): FormGroup {
    return this.dependents.at(index) as FormGroup;
  }

  handleMedConChange(entryGroup: FormGroup, value: string) {
    this.adult1MedicalRequired = false;
    this.adult1MedicalRequired = false;
    this.depMedicalRequired = [];
    if (value === 'yes') {
      entryGroup.get('medConSelected')?.setValue(true);
    } else {
      entryGroup.get('medConSelected')?.setValue(false);
    }
  }

  removeDependents(index: number) {
    //this is for added an assessment dependent, then add a non-assessment dependent, then remove the assessment dependent
    this.medicalService.saveTravelerDetails(this.travelerDetailsForm.value);
    let newTravelerDetails =
      this.localCacheService.getSessionStorage('travelerDetails');
    const travelerId = newTravelerDetails.dependents[index].travelerId;
    const currentDependent = newTravelerDetails.dependents[index];
    if (
      currentDependent.medConSelected &&
      currentDependent.dependent_med_con == 'yes'
    ) {
      this.confirmationService.confirm({
        message:
          'This dependent has assessment, Are you sure that you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        rejectButtonStyleClass: 'p-button-text',
        accept: () => {
          this.dependents.removeAt(index);
          let newAssessmentDetails =
            this.localCacheService.getSessionStorage('assessmentDetails');
          newAssessmentDetails.dependent =
            newAssessmentDetails.dependent.filter(
              (traveler: any) => traveler.travelerId !== travelerId,
            );
          this.localCacheService.saveSessionStorage(
            'assessmentDetails',
            newAssessmentDetails,
          );
          newTravelerDetails.dependents.splice(index, 1);
          //this.localCacheService.saveSessionStorage(
          //  'travelerDetails',
          //  newTravelerDetails,
          //);
          let newQuoteEnquiry =
            this.localCacheService.getSessionStorage('quoteEnquiry');
          newQuoteEnquiry.dependentsCount =
            this.travelerDetailsForm.value.dependents.length;
          this.localCacheService.saveSessionStorage(
            'quoteEnquiry',
            newQuoteEnquiry,
          );
          this.quoteService
            .submitQuote(newTravelerDetails, 'update-traveler-detail')
            .subscribe({
              next: (response) => {
                if (!response.error) {
                  this.quoteService
                    .getQuoteByQuoteId(this.quoteDetail.quoteId)
                    .subscribe((response) => {
                      if (!response.error) {
                        this.localCacheService.saveSessionStorage(
                          'quoteDetail',
                          response,
                        );
                        this.quoteDetail = response;
                        this.checkingReasessment();
                      }
                    });
                }
              },
              error: (error) => {
                console.log('update-traveler-detail', error);
              },
            });
        },
        reject: () => {
          console.log('dont delete dependent');
        },
      });
    } else {
      this.dependents.removeAt(index);
      newTravelerDetails.dependents.splice(index, 1);
      this.depMedicalRequired[index] = false;
      //this.localCacheService.saveSessionStorage(
      //  'travelerDetails',
      //  newTravelerDetails,
      //);
    }
  }

  showAdult2Section(hasAdult2: boolean) {
    const travelerDetails =
      this.localCacheService.getSessionStorage('travelerDetails') || {};
    if (hasAdult2) {
      const adult2FormGroup = this.formBuilder.group({
        firstName: [
          { value: travelerDetails.adult2?.firstName ?? '', disabled: true },
          [
            this.medicalService.nameShouldNotExceed50AndNotContainNumbers(
              'Secondary Traveller',
              'First Name',
            ),
          ],
        ],
        lastName: [
          { value: travelerDetails.adult2?.lastName ?? '', disabled: true },
          [
            this.medicalService.nameShouldNotExceed50AndNotContainNumbers(
              'Secondary Traveller',
              'Last Name',
            ),
          ],
        ],
        dateOfBirth: [
          {
            value: travelerDetails.adult2?.dateOfBirth ?? '',
            disabled: true,
          },
          Validators.required,
        ],
        two_med_con: new FormControl(travelerDetails.adult2?.two_med_con ?? ''),
      });
      this.travelerDetailsForm.addControl('adult2', adult2FormGroup);
      this.travelerDetailsForm
        .get('adult2.two_med_con')
        ?.setValue(travelerDetails.adult2?.two_med_con ?? '');
    } else {
      this.travelerDetailsForm.removeControl('adult2');
      this.travelerDetailsForm.updateValueAndValidity();
    }
  }

  redirectToProductPage(): void {
    this.router.navigate(['/quotes-steps-one']);
  }

  redirectToTravelerDetails(): void {
    this.validateBirthDateOnSubmit(
      this.travelerDetailsForm.get('adult1.dateOfBirth')?.value,
      'primary',
    );
    if (!this.isAdult1AgeValidOnSubmit) return;

    if (this.hasAdult2) {
      this.validateBirthDateOnSubmit(
        this.travelerDetailsForm.get('adult2.dateOfBirth')?.value,
        'secondary',
      );
      if (!this.isAdult2AgeValidOnSubmit) return;
    }
    if (
      this.medConditionSelected === 'yes' &&
      this.selectedCover?.selectedCoverTitle !== 'Rental Car Excess'
    ) {
      let hasNotCompleted = false;
      const assessmentDetails =
        this.localCacheService.getSessionStorage('assessmentDetails');
      const travelerDetails =
        this.localCacheService.getSessionStorage('travelerDetails') || {};
      if (assessmentDetails) {
        if (
          (this.travelerDetailsForm.value.adult1?.one_med_con === 'yes' &&
            ((assessmentDetails.primary &&
              assessmentDetails.primary.medicalPremium &&
              assessmentDetails.primary.medicalPremium.isNotCovered === true &&
              assessmentDetails.primary.medicalPremium.medicalConditions ===
                '') ||
              assessmentDetails?.primary?.medicalPremium?.status ===
                'Pending')) ||
          (this.travelerDetailsForm.value.adult1?.one_med_con === 'yes' &&
            !assessmentDetails?.primary?.medicalPremium &&
            this.primaryAssessmentStatus)
        ) {
          hasNotCompleted = true;
        }
        if (
          (this.travelerDetailsForm.value.adult2?.two_med_con === 'yes' &&
            ((assessmentDetails.secondary &&
              assessmentDetails.secondary.medicalPremium &&
              assessmentDetails.secondary.medicalPremium.isNotCovered ===
                true &&
              assessmentDetails.secondary.medicalPremium.medicalConditions ===
                '') ||
              assessmentDetails?.secondary?.medicalPremium?.status ===
                'Pending')) ||
          (this.travelerDetailsForm.value.adult2?.two_med_con === 'yes' &&
            !assessmentDetails?.secondary?.medicalPremium &&
            this.secondaryAssessmentStatus)
        ) {
          hasNotCompleted = true;
        }
        if (assessmentDetails.dependent) {
          assessmentDetails.dependent.forEach((dependent: any) => {
            let currentDep = travelerDetails.dependents.find(
              (t: any) => t.travelerId == dependent.travelerId,
            );
            if (
              (currentDep?.dependent_med_con === 'yes' &&
                dependent.medicalPremium &&
                dependent.medicalPremium.isNotCovered === true &&
                dependent.medicalPremium.medicalConditions === '') ||
              dependent?.medicalPremium?.status === 'Pending' ||
              (currentDep?.dependent_med_con === 'yes' &&
                !dependent.medicalPremium)
            ) {
              hasNotCompleted = true;
            }
          });
        }
      }
      if (hasNotCompleted) {
        this.confirmationService.confirm({
          message: `There are medical conditions that haven't been completed.`,
          acceptIcon: 'none',
          rejectVisible: false,
          acceptLabel: 'Ok',
          accept: () => {},
          reject: () => {},
        });
        return;
      }
    } else {
      // set medical assessment to 'no'
      this.travelerDetailsForm.get('adult1.one_med_con')?.setValue('');
      this.travelerDetailsForm
        .get('adult1.one_med_con')
        ?.updateValueAndValidity();
      this.travelerDetailsForm.get('adult2.two_med_con')?.setValue('');
      this.travelerDetailsForm
        .get('adult2.two_med_con')
        ?.updateValueAndValidity();
      this.dependents.controls.forEach((element) => {
        element.get('dependent_med_con')?.setValue('');
        element.get('dependent_med_con')?.updateValueAndValidity();
      });
    }
    //METHOD: HANDLE FORM SUBMISSION
    this.onBlurPostCode();
    if (this.travelerDetailsForm.valid) {
      if (this.validateIfMedicalRequiredForYes()) {
        this.submitTravelerDetails();
        this.segmentService.segmentTravellerDetailsAndConditionsEvent();
      }
    } else {
      this.travelerDetailsForm.markAllAsTouched();
      let travelForm = document.getElementById('travelerDetails');
      let firstInvalidControl = travelForm?.querySelector('.ng-invalid');
      if (firstInvalidControl) {
        firstInvalidControl.scrollIntoView({ behavior: 'smooth' });
      }
      this.sendAdobeConfirmFinaliseQuoteErrorMessageEvent(this.travelerDetailsForm);
    }
  }

  validateIfMedicalRequiredForYes(): boolean {
    this.adult1MedicalRequired = false;
    this.adult1MedicalRequired = false;
    this.depMedicalRequired = [];
    var validated: boolean = true;
    if (
      !this.primaryAssessmentStatus &&
      this.travelerDetailsForm.value.adult1 &&
      this.travelerDetailsForm.value.adult1.one_med_con == 'yes'
    ) {
      document.getElementById('adult1_firstName')?.focus();
      this.adult1MedicalRequired = true;
      validated = false;
    }

    if (
      !this.secondaryAssessmentStatus &&
      this.travelerDetailsForm.value.adult2 &&
      this.travelerDetailsForm.value.adult2.two_med_con == 'yes'
    ) {
      if (validated) {
        document.getElementById('adult2_firstName')?.focus();
      }
      this.adult2MedicalRequired = true;
      validated = false;
    }

    if (this.travelerDetailsForm.value.dependents) {
      for (
        let index = 0;
        index < this.travelerDetailsForm.value.dependents.length;
        index++
      ) {
        const dependentGroup = this.dependents.at(index) as FormGroup;
        const dependent_med_con = dependentGroup.get('dependent_med_con');
        const hasAssessment = dependentGroup.get('hasAssessment');

        if (!hasAssessment?.value && dependent_med_con?.value == 'yes') {
          if (validated) {
            document.getElementById(`dep${index}_firstName`)?.focus();
          }
          this.depMedicalRequired[index] = true;
          validated = false;
        }
      }
    }
    return validated;
  }
  loadValidations() {
    this.validationService
      .clubValidation()
      .pipe(takeUntil(this.destroySub$))
      .subscribe({
        next: (response) => {
          if (response) {
            this.validationsList = response;
            this.prepareErrorMessages();
            this.setDefaultValuesForValidationRules(response);
          } else {
            console.log('clubValidation response error');
          }
        },
        error: (error) => {
          console.log('clubValidation subscription error', error);
        },
      });
  }
  onChangeState(event: any) {
    const selectedState = stateList.filter(
      (x) => x.code === event.target.value,
    )[0];
    if (selectedState) {
      this.confirmationService.confirm({
        message: `State entered does not match with that provided in Step 1. <br/> 
          Stamp Duty may be recalculated based on the State entered here. <br/>
          Are you sure you want to proceed?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        rejectButtonStyleClass: 'p-button-text',
        accept: () => {
          this.travelerDetailsForm.patchValue({
            contactAndAddressDetails: {
              address: '',
              street: '',
              subUrb: '',
              postalCode: '',
            },
          });
          this.quoteEnquiry.dependentsCount =
            this.travelerDetailsForm.value.dependents.length;
          this.quoteEnquiry.state = selectedState;
          this.localCacheService.saveSessionStorage(
            'quoteEnquiry',
            this.quoteEnquiry,
          );
          this.quoteService
            .submitQuote(this.quoteEnquiry, 'update-traveler-address')
            .subscribe({
              next: (response) => {
                if (!response.error) {
                  this.localCacheService.saveSessionStorage(
                    'quoteDetail',
                    response,
                  );
                  this.quoteDetail = response;
                }
              },
              error: (error) => {
                console.log('update-traveler-address', error);
              },
            });
        },
        reject: () => {
          this.quoteEnquiry =
            this.localCacheService.getSessionStorage('quoteEnquiry');
          this.travelerDetailsForm.patchValue({
            contactAndAddressDetails: {
              state: this.quoteEnquiry?.state?.code || '',
            },
          });
        },
      });
    }
  }

  prepareErrorMessages() {
    this.validationsList.forEach((validation) => {
      if (!this.errorMessages[validation.field]) {
        this.errorMessages[validation.field] = {};
      }

      validation.rules.forEach((rule: Rule) => {
        this.errorMessages[validation.field][rule.type] = rule.message;
      });
    });
  }

  medicalDeclaration(): Observable<any> {
    if (
      this.medicalDeclarationForm.valid &&
      this.medicalDeclarationForm.get('medicalTreatment')?.value === 'no' &&
      (this.medicalDeclarationForm.get('investigation')?.value === 'no' ||
        this.medicalDeclarationForm.get('investigation')?.value === 'yes') &&
      this.medicalDeclarationForm.get('medicalCondition')?.value === 'yes' &&
      (this.medicalDeclarationForm.get('purchasePolicy')?.value === 'no' ||
        this.medicalDeclarationForm.get('purchasePolicy')?.value === 'yes')
    ) {
      this.medicalService
        .medicalDeclaration({
          transactionId: this.quoteDetail.quoteId,
          travelerId:
            this.globalService.getCurrentAssessmentDetails()?.travelerId,
          declarations: [
            this.medicalDeclarationForm.get('medicalTreatment')?.value,
            this.medicalDeclarationForm.get('investigation')?.value,
            this.medicalDeclarationForm.get('purchasePolicy')?.value,
            this.medicalDeclarationForm.get('medicalCondition')?.value,
          ],
        })
        .subscribe((response) => {
          console.log(response);
        });
      return of(true);
    } else {
      console.log('medicalDeclarationForm is invalid');
      return of(false);
    }
  }

  acceptMedical(status: string) {
    const currentAssessment =
      this.localCacheService.getSessionStorage('currentAssessment');
    const dependentIndex =
      this.localCacheService.getSessionStorage('dependentIndex');
    const details = this.globalService.getCurrentAssessmentDetails();
    const result = details?.xmlResult ?? '';
    this.medicalService
      .acceptMedical({
        transactionId: this.quoteDetail.quoteId,
        travelerId: details?.travelerId,
        xmlResult: result,
        isUpdated: true,
        status: status,
      })
      .subscribe((response) => {
        if (response) {
          this.medicalDeclarationForm.reset();
          this.quoteService
            .getQuoteByQuoteId(this.quoteDetail.quoteId)
            .subscribe((response) => {
              if (!response.error) {
                // response= this.persistMedicalPremiumOnDecline(response,details?.travelerId);
                this.localCacheService.saveSessionStorage(
                  'quoteDetail',
                  response,
                );
                this.quoteDetail = response;
              }
            });
        }
        this.showAcceptMedicalAssessmentModal = false;
        if (currentAssessment === 'primary') {
          this.primaryHasAssessment = true;
          this.primaryAssessmentStatus = 'Offer ' + status;
        } else if (currentAssessment === 'secondary') {
          this.secondaryHasAssessment = true;
          this.secondaryAssessmentStatus = 'Offer ' + status;
        } else if (currentAssessment === 'dependent') {
          const dependentForm = this.getDependentFormGroup(dependentIndex);
          dependentForm.get('hasAssessment')?.setValue(true);
          dependentForm.get('assessmentStatus')?.setValue('Offer ' + status);
        }
      });

    let assessmentLastName: any;
    let assessmentFirstName: any;
    let assessmentDateOfBirth: any;
    const medicalPremium =
      this.localCacheService.getSessionStorage('medicalPremium');
    const travelerDetails =
      this.localCacheService.getSessionStorage('travelerDetails');
    switch (currentAssessment) {
      case 'primary':
        assessmentLastName = travelerDetails.adult1.lastName;
        assessmentFirstName = travelerDetails.adult1.firstName;
        assessmentDateOfBirth = travelerDetails.adult1.dateOfBirth;
        break;
      case 'secondary':
        assessmentLastName = travelerDetails.adult2.lastName;
        assessmentFirstName = travelerDetails.adult2.firstName;
        assessmentDateOfBirth = travelerDetails.adult2.dateOfBirth;
        break;
      case 'dependent':
        assessmentLastName =
          travelerDetails.dependents[dependentIndex].lastName;
        assessmentFirstName =
          travelerDetails.dependents[dependentIndex].firstName;
        assessmentDateOfBirth =
          travelerDetails.dependents[dependentIndex].dateOfBirth;
        break;
    }

    const medAssessment = {
      eventName: `step_two_medical_assessment_${status}`,
      currentAssessment: currentAssessment,
      medicalCondition: medicalPremium.medicalConditions,
      lastName: assessmentLastName,
      firstName: assessmentFirstName,
      dateOfBirth: assessmentDateOfBirth,
    };
    this.segmentService.segmentMedicalAssessmentDetails(medAssessment);
  }

  validateBirthDateOnSelect(date: any, role: string) {
    if (date === '') return;
    let currentAge;
    let field;
    const newAge = this.agePipe.transform(date);
    if (this.quoteEnquiry.isSingleTrip) {
      if (role === 'primary') {
        this.isAdult1AgeValid = true;
        field = 'first_age';
        currentAge = this.quoteEnquiry.age1;
      } else {
        this.isAdult2AgeValid = true;
        field = 'second_age';
        currentAge = this.quoteEnquiry.age2;
      }
    } else {
      if (role === 'primary') {
        this.isAdult1AgeValid = true;
        field = 'first_age_multi';
        currentAge = this.quoteEnquiry.age1;
      } else {
        this.isAdult2AgeValid = true;
        field = 'second_age_multi';
        currentAge = this.quoteEnquiry.age2;
      }
    }
    if (currentAge === newAge) {
      return;
    } else {
      if (
        newAge < this.getValidationRule('min', field) ||
        newAge > this.getValidationRule('max', field)
      ) {
        if (role === 'primary') {
          this.isAdult1AgeValid = false;
        } else {
          this.isAdult2AgeValid = false;
        }
        return;
      }
    }
  }

  validateBirthDate(event: any, role: string) {
    if (event.target.value === '') return;
    let currentAge;
    let field;
    let currentDateOfBirth: any;
    const newAge = this.agePipe.transform(event.target.value);

    if (this.quoteEnquiry.isSingleTrip) {
      if (role === 'primary') {
        this.isAdult1AgeValid = true;
        field = 'first_age';
        currentAge = this.quoteEnquiry.age1;
      } else {
        this.isAdult2AgeValid = true;
        field = 'second_age';
        currentAge = this.quoteEnquiry.age2;
      }
    } else {
      if (role === 'primary') {
        this.isAdult1AgeValid = true;
        field = 'first_age_multi';
        currentAge = this.quoteEnquiry.age1;
      } else {
        this.isAdult2AgeValid = true;
        field = 'second_age_multi';
        currentAge = this.quoteEnquiry.age2;
      }
    }

    if (currentAge === newAge) {
      return;
    } else {
      if (
        newAge < this.getValidationRule('min', field) ||
        newAge > this.getValidationRule('max', field)
      ) {
        if (role === 'primary') {
          this.isAdult1AgeValid = false;
        } else {
          this.isAdult2AgeValid = false;
        }
        return;
      }

      let newTravelerDetails =
        this.localCacheService.getSessionStorage('travelerDetails');

      if (role === 'primary') {
        currentDateOfBirth = this.quoteDetail.travelers.find(
          (x: any) => x.role === 'primary',
        )?.dateOfBirth;
      } else if (role === 'secondary') {
        currentDateOfBirth = this.quoteDetail.travelers.find(
          (x: any) => x.role === 'secondary',
        )?.dateOfBirth;
      }

      if (currentAge != newAge) {
        this.adobeAnalyticsService.sendAlertMessage(
          'Date of Birth entered does not match the age entered.',
        );
        this.confirmationService.confirm({
          target: event.target as EventTarget,
          message:
            'Date of Birth entered does not match the age entered.<br/>Premium may be recalculated based on the Date of Birth entered.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          acceptButtonStyleClass: 'btn btn-info text-light',
          rejectButtonStyleClass: 'btn btn-outline-info',
          accept: () => {
            if (newTravelerDetails) {
              if (newTravelerDetails.adult1 && role === 'primary') {
                newTravelerDetails.adult1.dateOfBirth = event.target.value;
              } else if (role === 'secondary') {
                if (newTravelerDetails.adult2) {
                  newTravelerDetails.adult2.dateOfBirth = event.target.value;
                } else {
                  newTravelerDetails.adult2 = {
                    dateOfBirth: event.target.value,
                  };
                }
              }
            } else {
              if (role === 'primary') {
                newTravelerDetails = {
                  adult1: { dateOfBirth: event.target.value },
                };
              } else {
                newTravelerDetails = {
                  adult2: { dateOfBirth: event.target.value },
                };
              }
            }

            let newQuoteEnquiry =
              this.localCacheService.getSessionStorage('quoteEnquiry');

            if (newQuoteEnquiry) {
              if (role === 'primary') {
                newQuoteEnquiry.age1 = newAge;
              } else {
                newQuoteEnquiry.age2 = newAge;
              }
              this.localCacheService.saveSessionStorage(
                'quoteEnquiry',
                newQuoteEnquiry,
              );

              this.quoteEnquiry = newQuoteEnquiry;
            }
            this.localCacheService.saveSessionStorage(
              'travelerDetails',
              newTravelerDetails,
            );

            this.localCacheService.saveSessionStorage('ageChanged', true);
            this.quoteService
              .submitQuote(newTravelerDetails, 'update-traveler-detail')
              .subscribe({
                next: (response) => {
                  if (!response.error) {
                    this.localCacheService.saveSessionStorage(
                      'quoteDetail',
                      response,
                    );
                    this.quoteDetail = response;
                    this.checkingReasessment();
                  }
                },
                error: (error) => {
                  console.log('update-traveler-detail', error);
                },
              });
          },
          reject: () => {
            if (role === 'primary') {
              this.travelerDetailsForm
                .get('adult1.dateOfBirth')
                ?.setValue(currentDateOfBirth);
            } else {
              this.travelerDetailsForm
                .get('adult2.dateOfBirth')
                ?.setValue(currentDateOfBirth);
            }
          },
        });
      }
    }
  }

  validateDependentBirthdate() {
    return (control: AbstractControl): ValidationErrors | null => {
      const age = this.agePipe.transform(control.value);
      if (Number(age) > 25) {
        return {
          dependentAgeIsNotValid:
            'Dependents over the age of 25 must purchase their own policy or be considered another applicant on this policy.',
        };
      }
      return null;
    };
  }

  reDoAssessment(event: any, role: string, dependentIndex: number = -1) {
    event.preventDefault();
    const currentAssessment = role;
    this.localCacheService.saveSessionStorage(
      'currentAssessment',
      currentAssessment,
    );
    if (role === 'dependent') {
      this.localCacheService.saveSessionStorage(
        'dependentIndex',
        dependentIndex,
      );
    }
    let detail = this.globalService.getCurrentAssessmentDetails();
    this.medicalService.decryptXMLData().subscribe((result) => {
      xml2js.parseString(result.DecryptedXml, (err: any, res: any) => {
        if (err) {
          throw new Error('Error parsing XML');
        }
        this.medicalService
          .veriskReCalculate(
            res.Screening?.ScreeningPath[0]?.SystemData[0]?.ScreeningData[0],
          )
          .subscribe((veriskResponse) => {
            //update new re calculate xml result from verisk into session
            //send new xml to premium and get new premium
            const medicalPremium: MedicalPremium = {
              transactionId: detail?.transactionId,
              travelerId: detail?.travelerId,
              xmlResult: veriskResponse.Result[0].Result,
              isUpdated: true,
            };
            this.medicalService
              .addMedicalPremium(medicalPremium)
              .subscribe((response) => {
                // Here is a rare scenario when re-calculate cause not cover, how to handle this case?
                this.localCacheService.saveSessionStorage(
                  'medicalPremium',
                  response,
                );
                detail.xmlResult = veriskResponse.Result[0].Result;
                detail.medicalPremium = response;
                const assessmentDetails =
                  this.localCacheService.getSessionStorage('assessmentDetails');
                const currentAssessment =
                  this.localCacheService.getSessionStorage('currentAssessment');
                if (
                  currentAssessment === 'primary' ||
                  currentAssessment === 'secondary'
                ) {
                  assessmentDetails[currentAssessment] = detail;
                } else if (currentAssessment === 'dependent') {
                  assessmentDetails.dependent.map((dependent: any) => {
                    if (dependent.travelerId === detail.travelerId) {
                      dependent.xmlResult = detail.xmlResult;
                      dependent.medicalPremium = detail.medicalPremium;
                    }
                  });
                }
                this.localCacheService.saveSessionStorage(
                  'assessmentDetails',
                  assessmentDetails,
                );
                this.currentAssessmentName =
                  this.globalService.getCurrentAssessmentName();
                this.medicalPremium =
                  this.localCacheService.getSessionStorage('medicalPremium');
                this.medicalConditions = this.medicalPremium?.medicalConditions
                  ? this.medicalPremium?.medicalConditions.split('|')
                  : [];
                this.showAcceptMedicalAssessmentModal = true;

                if (role === 'primary') {
                  this.primaryReassessment = false;
                } else if (role === 'secondary') {
                  this.secondaryReassessment = false;
                } else {
                  const dependentForm =
                    this.getDependentFormGroup(dependentIndex);
                  dependentForm.get('reassessment')?.setValue(false);
                }
              });
          });
      });
    });
  }

  getValidationRule(type: string, field: string) {
    for (const validation of this.validationsList) {
      for (const rule of validation.rules) {
        if (rule.type === type && validation.field === field) {
          return rule.value;
        }
      }
    }
  }

  updateMedicalCoverage(event: any, role: string, dependentIndex: number = -1) {
    event.preventDefault();
    this.localCacheService.saveSessionStorage('currentAssessment', role);
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    if (role === 'primary') {
      if (quoteDetail.travelers[0]) {
        this.currentAssessmentName =
          quoteDetail.travelers[0]?.firstName +
          ' ' +
          quoteDetail.travelers[0]?.lastName;
        this.medicalPremium.totalAdditionalPremiumAmount =
          quoteDetail.travelers[0]?.medicalInfo?.medicalPremium ?? 0;
        this.medicalConditions = quoteDetail.travelers[0]?.medicalInfo
          ?.medicalConditions
          ? quoteDetail.travelers[0]?.medicalInfo?.medicalConditions.split('|')
          : [];
        this.medicalPremium.isNotCovered =
          quoteDetail.travelers[0]?.medicalInfo?.status === 'Not Covered';
        this.medicalPremium.medicalConditions =
          this.medicalConditions.join(',');
      } else {
        const medicalPremium =
          this.globalService.getCurrentAssessmentDetails()?.medicalPremium;
        this.currentAssessmentName =
          this.globalService.getCurrentAssessmentName();
        this.medicalPremium.totalAdditionalPremiumAmount =
          medicalPremium?.totalAdditionalPremiumAmount ?? 0;
        this.medicalPremium.isNotCovered =
          medicalPremium?.isNotCovered ?? false;
        this.medicalConditions = medicalPremium?.medicalConditions
          ? medicalPremium?.medicalConditions.split('|')
          : [];
        this.medicalPremium.medicalConditions =
          medicalPremium?.medicalConditions ?? '';
      }
    } else if (role === 'secondary') {
      if (quoteDetail.travelers[1]) {
        this.currentAssessmentName =
          quoteDetail.travelers[1]?.firstName +
          ' ' +
          quoteDetail.travelers[1]?.lastName;
        this.medicalPremium.totalAdditionalPremiumAmount =
          quoteDetail.travelers[1]?.medicalInfo?.medicalPremium ?? 0;
        this.medicalConditions = quoteDetail.travelers[1]?.medicalInfo
          ?.medicalConditions
          ? quoteDetail.travelers[1]?.medicalInfo?.medicalConditions.split(',')
          : [];
        this.medicalPremium.isNotCovered =
          quoteDetail.travelers[1]?.medicalInfo?.status === 'Not Covered';
        this.medicalPremium.medicalConditions =
          this.medicalConditions.join(',');
      } else {
        const medicalPremium =
          this.globalService.getCurrentAssessmentDetails()?.medicalPremium;
        this.currentAssessmentName =
          this.globalService.getCurrentAssessmentName();
        this.medicalPremium.totalAdditionalPremiumAmount =
          medicalPremium?.totalAdditionalPremiumAmount ?? 0;
        this.medicalPremium.isNotCovered =
          medicalPremium?.isNotCovered ?? false;
        this.medicalConditions = medicalPremium?.medicalConditions
          ? medicalPremium?.medicalConditions.split('|')
          : [];
        this.medicalPremium.medicalConditions =
          medicalPremium?.medicalConditions ?? '';
      }
    } else {
      this.localCacheService.saveSessionStorage(
        'dependentIndex',
        dependentIndex,
      );
      const travelerDetails =
        this.localCacheService.getSessionStorage('travelerDetails');
      const travelerId = travelerDetails.dependents[dependentIndex].travelerId;
      const dependent = quoteDetail.travelers.find(
        (traveler: any) => traveler.travelerId === travelerId,
      );
      if (dependent) {
        this.currentAssessmentName =
          dependent?.firstName + ' ' + dependent?.lastName;
        this.medicalPremium.totalAdditionalPremiumAmount =
          dependent?.medicalInfo?.medicalPremium ?? 0;
        this.medicalConditions = dependent?.medicalInfo?.medicalConditions
          ? dependent?.medicalInfo?.medicalConditions.split('|')
          : [];
        this.medicalPremium.isNotCovered =
          dependent?.medicalInfo?.status === 'Not Covered';
        this.medicalPremium.medicalConditions =
          this.medicalConditions.join(',');
      } else {
        const medicalPremium =
          this.globalService.getCurrentAssessmentDetails()?.medicalPremium;
        this.currentAssessmentName =
          this.globalService.getCurrentAssessmentName();
        this.medicalPremium.totalAdditionalPremiumAmount =
          medicalPremium?.totalAdditionalPremiumAmount ?? 0;
        this.medicalPremium.isNotCovered =
          medicalPremium?.isNotCovered ?? false;
        this.medicalConditions = medicalPremium?.medicalConditions
          ? medicalPremium?.medicalConditions.split('|')
          : [];
        this.medicalPremium.medicalConditions =
          medicalPremium?.medicalConditions ?? '';
      }
    }
    this.showAcceptMedicalAssessmentModal = true;
  }

  shouldSelectYesOrNo() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.medConditionSelected && this.medConditionSelected === 'yes') {
        // Check if there are countries that is considered as sanctioned

        if (!control.value)
          return {
            shouldSelectYesOrNo: `Please select either Yes or No to continue. By
                              selecting a ‘Yes’ , you are required to answer a
                              pre-medical assessment questionnaire.`,
          };
      }
      return null; // no errors
    };
  }

  downloadPDS() {
    let isMobileView = window.innerWidth < 768;
    const button = document.getElementById('downloadPDSButton');

    if (isMobileView) {
      if (button) {
        button.classList.remove('hidden');
      }
    }

    this.pdsService.downloadLatestPDSPDF();
    if (isMobileView) {
      if (button) {
        setTimeout(() => {
          button.classList.add('hidden');
        }, 1000);
      }
    }
  }

  onBlurNameField(form: any, field: string) {
    let value = form.get(field).value.trim();

    if (
      this.medicalService.containsDecimalNumbers(value) ||
      !this.medicalService.isValidName(value)
    ) {
      value = value.replace(/\d+/g, '');
      value = value.replace(/[^a-zA-Z-\s',’]/g, '');
    }

    const error = form.get(field).errors;

    form.get(field).patchValue(value);
    if (error && error.nameValidationMessage) {
      form.get(field).setErrors(error);
    } else {
      if (
        field === 'adult1.lastName' &&
        environment.autoClub === 'RACV' &&
        this.quoteEnquiry.isClubMember === 'true'
      ) {
        this.travelerDetailsForm.get('adult1.membershipNumber')?.enable();
      }
    }
  }
  validateMembershipNumber(event: any) {
    const membershipNumber = event.target.value;
    const familyName = this.travelerDetailsForm.get('adult1.lastName')?.value;
    if (membershipNumber) {
      this.membershipService
        .validateMembershipNumber(membershipNumber, familyName)
        .subscribe({
          next: (response) => {
            if (!response.error && response) {
              let newQuoteDetail =
                this.localCacheService.getSessionStorage('quoteDetail');
              newQuoteDetail.membershipNumber = membershipNumber;
              this.localCacheService.saveSessionStorage(
                'quoteDetail',
                newQuoteDetail,
              );
            } else {
              this.showMembershipValidationPopup(response);
            }
          },
          error: (error) => {
            console.log('validateMembershipNumber subscription error', error);
          },
        });
    }
  }
  showMembershipValidationPopup(response: any) {
    this.adobeAnalyticsService.sendAlertMessage(
      'The member number you have entered does not match our records.',
    );
    this.confirmationService.confirm({
      message: `<strong>${environment.autoClub} Member Number</br><br/>
        The member number you have entered does not match our records.</br><br/>
        Please check the information you have entered is correct and that First Name, Last Name, Date of Birth and Member Number are all from the same account.</br><br/>
        If you would like to re-enter your information please click the try again button.</br><br/>
        Otherwise you can continue without the member discount but the quote will be updated without the member discount applied.</br><br/>
        If you have any additional questions please contact us on 13 13 29.</strong>`,
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: 'Continue',
      acceptButtonStyleClass: 'btn btn-info btn-lg nohover',
      rejectLabel: 'Try Again',
      rejectButtonStyleClass: 'btn btn-info btn-lg me-2 nohover',
      defaultFocus: 'none',
      accept: () => {
        this.showMemebershipNumber = false;
        this.localCacheService.saveSessionStorage('membershipRemoved', true);
        let cover = this.localCacheService.getSessionStorage(
          'selectedCoverDetail',
        );
        cover.selectedPrice.membershipDisc = 0;
        this.localCacheService.saveSessionStorage('selectedCoverDetail', cover);
        this.selectedCover = cover;
        this.quoteEnquiry =
          this.localCacheService.getSessionStorage('quoteEnquiry');
        this.quoteEnquiry.isClubMember = 'false';
        this.quoteDetail.membershipNumber = '';
        this.localCacheService.saveSessionStorage(
          'quoteEnquiry',
          this.quoteEnquiry,
        );
        //this.resetPricingDetail(newQuoteEnquiry);

        const membershipField = this.travelerDetailsForm.get(
          'adult1.membershipNumber',
        );
        membershipField?.setValue('');
        membershipField?.setValidators([]);
        membershipField?.updateValueAndValidity();
        this.quoteService
          .submitQuote(response, 'update-traveler-detail')
          .subscribe({
            next: (submitResponse) => {
              if (!submitResponse.error) {
                this.quoteDetail = submitResponse;
                this.localCacheService.saveSessionStorage(
                  'quoteDetail',
                  submitResponse,
                );
              }
            },
            error: (error) => {
              console.log(
                'validateMembershipNumber update-traveler-detail error',
                error,
              );
            },
          });
      },
      reject: () => {
        const membershipField = this.travelerDetailsForm.get(
          'adult1.membershipNumber',
        );
        membershipField?.markAsTouched();
        membershipField?.setValue('');
        membershipField?.setValidators(
          this.quoteEnquiry.isClubMember === 'true' ||
            this.quoteEnquiry.isClubMember === true
            ? Validators.required
            : [],
        );
        membershipField?.updateValueAndValidity();
      },
    });
  }
  disablePaste(event: ClipboardEvent): void {
    event.preventDefault();
  }

  notCoveredMedical(conditions: string) {
    const currentAssessment =
      this.localCacheService.getSessionStorage('currentAssessment');
    const dependentIndex =
      this.localCacheService.getSessionStorage('dependentIndex');
    let statusString = 'Offer Pending';
    if (conditions !== '') {
      statusString = 'Not Covered';
    }
    if (currentAssessment === 'primary') {
      this.primaryHasAssessment = true;
      this.primaryAssessmentStatus = statusString;
    } else if (currentAssessment === 'secondary') {
      this.secondaryHasAssessment = true;
      this.secondaryAssessmentStatus = statusString;
    } else if (currentAssessment === 'dependent') {
      const dependentForm = this.getDependentFormGroup(dependentIndex);
      dependentForm.get('hasAssessment')?.setValue(true);
      dependentForm.get('assessmentStatus')?.setValue(statusString);
    }
    this.quoteService
      .getQuoteByQuoteId(this.quoteDetail.quoteId)
      .subscribe((response) => {
        if (!response.error) {
          this.localCacheService.saveSessionStorage('quoteDetail', response);
          this.quoteDetail = response;
          this.setTravelerDetails(response);
          this.hasAdult2 =
            this.quoteDetail.ages?.length > 1 && this.quoteDetail.ages[1] > 0
              ? true
              : false;
          this.hasDependent = Number(this.quoteDetail.dependentsCount) > 0;
        }
        this.showAcceptMedicalAssessmentModal = false;
      });
  }

  setDefaultValuesForValidationRules(validationsList: any[]) {
    validationsList.forEach((validation: any) => {
      if (validation.field === 'first_age') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.firstMinAge = rule.value;
          } else if (rule.type === 'max') {
            this.firstMaxAge = rule.value;
          }
        });
      } else if (validation.field === 'second_age') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.secondMinAge = rule.value;
          }
          if (rule.type === 'max') {
            this.secondMaxAge = rule.value;
          }
        });
      } else if (validation.field === 'membership_number') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'max') {
            this.membershipNoMaxLength = rule.value;
          }
        });
      }
    });
    const adult1FormGroup = this.travelerDetailsForm.get('adult1') as FormGroup;
    adult1FormGroup
      .get('membership_number')
      ?.addValidators([Validators.maxLength(this.membershipNoMaxLength)]);
  }

  isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }
  handleTravelerMedConChange(event: any, role: string) {
    if (role === 'primary') {
      this.adult1MedConSelected = event.target.value === 'yes';
      if (event.target.value === 'yes') {
        this.travelerDetailsForm.get('adult1')?.markAllAsTouched();
      }
    }
    if (role === 'secondary') {
      this.adult2MedConSelected = event.target.value === 'yes';
    }
  }
  setTravelerDetails(response: any) {
    // build traveler details session and assessment details session
    if (response.travelers.length > 0) {
      let storedTravelerDetails = {} as any;
      let storedAssessmentDetails = {} as any;
      storedTravelerDetails.medical_con = 'no';
      response.travelers.forEach((trvl: any) => {
        if (trvl.medicalInfo?.xml) {
          storedTravelerDetails.medical_con = 'yes';
        }
        if (trvl.role == 'primary') {
          storedTravelerDetails.adult1 = {
            firstName: trvl.firstName ?? '',
            lastName: trvl.lastName ?? '',
            dateOfBirth: trvl.dateOfBirth ?? '',
            membershipNumber: response.membershipNumber ?? '',
            one_med_con: trvl.medicalInfo?.xml ? 'yes' : 'no',
          };

          storedTravelerDetails.contactAndAddressDetails = {
            emailId: trvl?.email,
            confEmailId: trvl.email ?? '',
            phoneNumber: trvl.phone ?? '',
            phoneNumber2: trvl.alternatePhone ?? '',
            postalCode: trvl.address?.postalCode ?? '',
            street: trvl.address?.address ?? '',
            subUrb: trvl.address?.city ?? '',
            state:
              stateList.find((state) => state.id == trvl.address?.stateId)
                ?.code ?? '',
          };

          if (trvl.medicalInfo?.xml) {
            storedAssessmentDetails.primary = {
              role: 'primary',
              transactionId: response.quoteId,
              travelerId: trvl.travelerId,
              xmlResult: trvl.medicalInfo?.xml,
            };
          }
        }

        if (trvl.role == 'secondary') {
          storedTravelerDetails.adult2 = {
            firstName: trvl.firstName ?? '',
            lastName: trvl.lastName ?? '',
            dateOfBirth: trvl.dateOfBirth ?? '',
            two_med_con: trvl.medicalInfo?.xml ? 'yes' : 'no',
          };

          if (trvl.medicalInfo?.xml) {
            storedAssessmentDetails.secondary = {
              role: 'secondary',
              transactionId: response.quoteId,
              travelerId: trvl.travelerId,
              xmlResult: trvl.medicalInfo?.xml,
            };
          }
        }

        if (trvl.role == 'dependent') {
          let _dep: any = {
            firstName: trvl.firstName ?? '',
            lastName: trvl.lastName ?? '',
            dateOfBirth: trvl.dateOfBirth ?? '',
            travelerId: trvl.travelerId,
          };

          //adding values for showing medical assessment for dependent
          _dep.assessmentStatus = trvl.medicalInfo?.status ?? '';
          _dep.hasAssessment = !_dep.assessmentStatus ? false : true;
          _dep.dependent_med_con = trvl.medicalInfo?.xml ? 'yes' : 'no';
          _dep.medConSelected = !_dep.assessmentStatus ? false : true;
          _dep.reassessment = trvl.medicalInfo?.rescore;

          storedTravelerDetails.dependents = storedTravelerDetails.dependents
            ? [...storedTravelerDetails.dependents, _dep]
            : [_dep];

          if (trvl.medicalInfo?.xml) {
            let _depAssessment = {
              role: 'dependent',
              transactionId: response.quoteId,
              travelerId: trvl.travelerId,
              xmlResult: trvl.medicalInfo?.xml,
            };
            storedAssessmentDetails.dependent =
              storedAssessmentDetails.dependent
                ? [...storedAssessmentDetails.dependent, _depAssessment]
                : [_depAssessment];
          }
        }
      });

      storedTravelerDetails.emergencyContact = {
        firstName: response.emergencyContact?.firstName ?? '',
        lastName: response.emergencyContact?.lastName ?? '',
        emailId: response.emergencyContact?.email ?? '',
        phoneNumber: response.emergencyContact?.phone ?? '',
      };
      this.localCacheService.saveSessionStorage(
        'travelerDetails',
        storedTravelerDetails,
      );
    }
  }

  onBlurPostCode() {
    const postCode = this.travelerDetailsForm.get(
      'contactAndAddressDetails.postalCode',
    );
    const selectedState = this.travelerDetailsForm.get(
      'contactAndAddressDetails.state',
    )?.value;

    if (postCode?.value) {
      this.addressifyService
        .statesForPostCode(postCode?.value)
        .subscribe((data) => {
          if (!data.map((x) => x.trim()).includes(selectedState)) {
            postCode?.setErrors({
              invalidPostCodeForState: 'Invalid Post Code',
            });
            this.adobeAnalyticsService.sendErrorMessages('Invalid Post Code');
          }
        });
    }
  }

  onBlurMobileNumber() {
    const phoneNumber = this.travelerDetailsForm.get(
      'contactAndAddressDetails.phoneNumber',
    );

    if (phoneNumber?.value && phoneNumber?.value.length < 10) {
      phoneNumber?.setErrors({
        phoneNumberInvalid: true,
      });
    }
  }

  triggerSegmentEvent(field: string) {
    let eventVal: any;
    let eventNameTitle: any;

    switch (field) {
      case 'exstMedConYes':
        eventNameTitle = 'step_two_existing_medical_condition';
        eventVal = 'Yes';
        break;
      case 'exstMedConNo':
        eventNameTitle = 'step_two_existing_medical_condition';
        eventVal = 'No';
        break;
      case 'saveEmailMain':
        eventNameTitle = 'step_two_body_save_and_email_quote';
        break;
      case 'downloadPdsMain':
        eventNameTitle = 'step_two_body_downlaod_pds';
        break;
      case 'saveEmailSideIcon':
        eventNameTitle = 'step_two_side_icon_save_and_email_quote';
        break;
      case 'downloadPdslSideIcon':
        eventNameTitle = 'step_two_side_icon_downlaod_pds';
        break;
    }

    const travelDetails = {
      eventName: eventNameTitle,
      value: eventVal,
    };

    this.segmentService.segmentIdentifyEventNameAndValue(travelDetails);
  }

  validateBirthDateOnSubmit(dateOfBirth: any, role: string) {
    if (dateOfBirth === '') return;
    let currentAge;
    let field;
    let currentDateOfBirth: any;
    const newAge = this.agePipe.transform(dateOfBirth);

    if (this.quoteEnquiry.isSingleTrip) {
      if (role === 'primary') {
        this.isAdult1AgeValidOnSubmit = true;
        field = 'first_age';
        currentAge = this.quoteEnquiry.age1;
      } else {
        this.isAdult2AgeValidOnSubmit = true;
        field = 'second_age';
        currentAge = this.quoteEnquiry.age2;
      }
    } else {
      if (role === 'primary') {
        this.isAdult1AgeValidOnSubmit = true;
        field = 'first_age_multi';
        currentAge = this.quoteEnquiry.age1;
      } else {
        this.isAdult2AgeValidOnSubmit = true;
        field = 'second_age_multi';
        currentAge = this.quoteEnquiry.age2;
      }
    }

    if (currentAge === newAge) {
      return;
    } else {
      if (
        newAge < this.getValidationRule('min', field) ||
        newAge > this.getValidationRule('max', field)
      ) {
        if (role === 'primary') {
          this.isAdult1AgeValidOnSubmit = false;
        } else {
          this.isAdult2AgeValidOnSubmit = false;
        }
        return;
      }

      let newTravelerDetails =
        this.localCacheService.getSessionStorage('travelerDetails');

      if (role === 'primary') {
        currentDateOfBirth = this.quoteDetail.travelers.find(
          (x: any) => x.role === 'primary',
        )?.dateOfBirth;
      } else if (role === 'secondary') {
        currentDateOfBirth = this.quoteDetail.travelers.find(
          (x: any) => x.role === 'secondary',
        )?.dateOfBirth;
      }

      if (currentAge != newAge) {
        if (role === 'primary') {
          this.isAdult1AgeValidOnSubmit = false;
        } else {
          this.isAdult2AgeValidOnSubmit = false;
        }
        this.confirmationService.confirm({
          message:
            'Date of Birth entered does not match the age entered.<br/>Premium may be recalculated based on the Date of Birth entered.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          acceptButtonStyleClass: 'btn btn-info text-light',
          rejectButtonStyleClass: 'btn btn-outline-info',
          accept: () => {
            if (role === 'primary') {
              this.isAdult1AgeValidOnSubmit = true;
            } else {
              this.isAdult2AgeValidOnSubmit = true;
            }
            if (newTravelerDetails) {
              if (newTravelerDetails.adult1 && role === 'primary') {
                newTravelerDetails.adult1.dateOfBirth = dateOfBirth;
              } else if (role === 'secondary') {
                if (newTravelerDetails.adult2) {
                  newTravelerDetails.adult2.dateOfBirth = dateOfBirth;
                } else {
                  newTravelerDetails.adult2 = {
                    dateOfBirth: dateOfBirth,
                  };
                }
              }
            } else {
              if (role === 'primary') {
                newTravelerDetails = {
                  adult1: { dateOfBirth: dateOfBirth },
                };
              } else {
                newTravelerDetails = {
                  adult2: { dateOfBirth: dateOfBirth },
                };
              }
            }

            let newQuoteEnquiry =
              this.localCacheService.getSessionStorage('quoteEnquiry');

            if (newQuoteEnquiry) {
              if (role === 'primary') {
                newQuoteEnquiry.age1 = newAge;
              } else {
                newQuoteEnquiry.age2 = newAge;
              }
              this.localCacheService.saveSessionStorage(
                'quoteEnquiry',
                newQuoteEnquiry,
              );

              this.quoteEnquiry = newQuoteEnquiry;
            }
            this.localCacheService.saveSessionStorage(
              'travelerDetails',
              newTravelerDetails,
            );

            this.localCacheService.saveSessionStorage('ageChanged', true);
            this.quoteService
              .submitQuote(newTravelerDetails, 'update-traveler-detail')
              .subscribe({
                next: (response) => {
                  if (!response.error) {
                    this.localCacheService.saveSessionStorage(
                      'quoteDetail',
                      response,
                    );
                    this.quoteDetail = response;
                    this.checkingReasessment();
                  }
                },
                error: (error) => {
                  console.log('update-traveler-detail', error);
                },
              });
          },
          reject: () => {
            if (role === 'primary') {
              this.travelerDetailsForm
                .get('adult1.dateOfBirth')
                ?.setValue(currentDateOfBirth);
              this.isAdult1AgeValidOnSubmit = false;
            } else {
              this.travelerDetailsForm
                .get('adult2.dateOfBirth')
                ?.setValue(currentDateOfBirth);
              this.isAdult2AgeValidOnSubmit = false;
            }
          },
        });
      }
    }
  }

  sendAdobeIneractionEvent(fieldName: string) {
    this.adobeAnalyticsService.sendInteractWithFields(fieldName);
  }

  sendAdobeButtonBarClickEvent(buttonName: string) {
    this.adobeAnalyticsService.sendButtonBarClick(buttonName);
  }

  sendAdobeSideBarClickEvent(buttonName: string) {
    this.adobeAnalyticsService.sendSideBarClick(buttonName);
  }

  sendAdobeNeedCallClickEvent() {
    this.adobeAnalyticsService.sendNeedCallClick();
  }

  sendAdobeAccordionClickEvent(description: string) {
    this.adobeAnalyticsService.sendAccordionClick(description);
  }

  sendAdobeErrorMessage(fieldName: string) {
    try {
      console.log('sendAdobeErrorMessage fieldName:', fieldName);
      console.log(
        'sendAdobeErrorMessage travelerDetailsForm:',
        this.travelerDetailsForm,
      );
      const validationObj = this.travelerDetailsForm.get(fieldName)?.errors;
      let message = validationObj ? JSON.stringify(validationObj) : '';
      if (message === '') return;
      const messageObj = JSON.parse(message);
      const messageKeyName = Object.keys(messageObj)[0];
      switch (fieldName) {
        case 'adult1.dateOfBirth':
        case 'adult2.dateOfBirth':
          message = this.errorMessages.dob[messageKeyName];
          break;
        case 'adult1.membershipNumber':
          message = this.errorMessages.membership_number[messageKeyName];
          break;
        case 'contactAndAddressDetails.emailId':
        case 'contactAndAddressDetails.confEmailId':
          if (messageKeyName === 'invalidEmail') {
            message = this.errorMessages.email_id['email'];
          } else {
            message = this.errorMessages.email_id[messageKeyName];
          }
          break;
        case 'contactAndAddressDetails.phoneNumber':
          message = this.errorMessages.phone_number[messageKeyName];
          break;
        case 'contactAndAddressDetails.street':
          message = this.errorMessages.street[messageKeyName];
          break;
        case 'contactAndAddressDetails.subUrb':
          message = this.errorMessages.suburb[messageKeyName];
          break;
        case 'contactAndAddressDetails.postalCode':
          message = this.errorMessages.postal_code[messageKeyName];
          break;
        default:
          message = messageObj ? messageObj[Object.keys(messageObj)[0]] : '';
      }
      this.adobeAnalyticsService.sendErrorMessages(message);
    } catch (error) {
      console.error('sendAdobeErrorMessage error:', error);
    }
  }

  sendAdobeDependentErrorMessage(fieldName: string) { 
    try {
      const dependentForm = this.travelerDetailsForm.controls['dependents'] as FormGroup;
      console.log('sendAdobeDependentErrorMessage dependentForm:', dependentForm);
      const parentDependentFormControls = dependentForm.controls;
      for (const key in parentDependentFormControls) {
        const dependentForm = parentDependentFormControls[key] as FormGroup;
        const dependentFormControls = dependentForm.controls;
        console.log('sendAdobeDependentErrorMessage dependentFormControls:', dependentFormControls);
        if(dependentFormControls.hasOwnProperty(fieldName)){
          const element = dependentFormControls[fieldName];
          if (element.errors) {
            const keyName = Object.keys(element.errors)[0];
            if (fieldName == 'dateOfBirth') { 
              const message = this.errorMessages['dob'][keyName];
              this.adobeAnalyticsService.sendErrorMessages(message);
            }
            else {
              const message = element.errors ? element.errors[Object.keys(element.errors)[0]] : '';
              this.adobeAnalyticsService.sendErrorMessages(message);
            }
          }
        }
      }
    } catch (error) {
      console.error('sendAdobeDependentErrorMessage error:', error);
    }
  }

  sendAdobeConfirmFinaliseQuoteErrorMessageEvent(form: any) {
    try {
      const parentFormControls = form.controls;
      for (const childForm in parentFormControls) {
        console.log('childForm:', childForm);
        const childFormControls = parentFormControls[childForm].controls;
        if (childForm == 'dependents') { 
          console.log('childFormControls:', childFormControls);
          for (const dep in childFormControls) {
            const depFormControls = childFormControls[dep].controls;
            for (const key in depFormControls) {
              console.log('key:', depFormControls, key);
              if (depFormControls.hasOwnProperty(key)) {
                const element = depFormControls[key];
                if (element.errors) {
                  if (key == 'dateOfBirth') {
                    const message = this.errorMessages['dob'][Object.keys(element.errors)[0]];
                    this.adobeAnalyticsService.sendErrorMessages(message);
                  } else {
                    const message = element.errors ? element.errors[Object.keys(element.errors)[0]] : '';
                    this.adobeAnalyticsService.sendErrorMessages(message);
                  }
                }
              }
            }
          }
        }
        for (const key in childFormControls) {
          if (childFormControls.hasOwnProperty(key)) {
            const element = childFormControls[key];
            if (element.errors) {
              const keyName = childForm.toString() + '.' + key.toString();
              this.sendAdobeErrorMessage(keyName);
            }
          }
        }
      }
    } catch (error) {
      console.error('sendAdobeGetaQuoteErrorMessageEvent error:', error);
    }
  }
}
