import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { RadioButtonModule } from 'primeng/radiobutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { AdobeAnalyticsService } from '../../services/adobe.analytics.service';

@Component({
  selector: 'app-choose-your-option',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    RadioButtonModule,
    FormsModule,
    OverlayPanelModule,
    ButtonModule,
    CalendarModule,
    TooltipModule,
  ],
  templateUrl: './choose-your-option.component.html',
  styleUrl: './choose-your-option.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChooseYourOptionComponent {
  @Input() quoteEnquiry: any;
  @Output() optionChange = new EventEmitter<string>();
  @Input() selectedCover: any;
  @Input() isCruise: boolean = false;
  @Input() isSki: boolean = true;
  selectedExcessValue: string = '';
  selectedExcessLabel: string = '$0';
  selectedCruiseValue: string = '';
  selectedCruiseLabel: string = '';
  selectedSportsValue: string = '';
  selectedSportsLabel: string = '';

  isTooltipVisible: boolean = false;
  isTooltipVisibleCruise: boolean = false;
  isTooltipVisibleSki: boolean = false;

  constructor(private adobeAnalyticsService: AdobeAnalyticsService  ) {}

  ngOnInit() {
    this.updateTooltipVisibility();

    if (this.selectedCover) {
      if (this.selectedCover?.selectedPrice?.isCruise) {
        this.selectedCruiseLabel = 'cruise_yes';
      }
      if (!this.selectedCover?.selectedPrice?.isCruise) {
        this.selectedCruiseLabel = 'cruise_no';
      }
      if (this.selectedCover?.selectedPrice?.isSki) {
        this.selectedSportsLabel = 'sports_yes';
      }
      if (!this.selectedCover?.selectedPrice?.isSki) {
        this.selectedSportsLabel = 'sports_no';
      }
      if (this.selectedCover?.selectedPrice?.excess) {
        this.selectedExcessLabel = this.selectedCover?.selectedPrice?.excess;
      }
    }
  }

  onExcessTooltip(label: string) {
    if (this.selectedCover?.selectedCoverTitle === 'Rental Car Excess') return;
    switch (label) {
      case '$500':
        return 'If you choose a higher excess it can reduce your premiums but it will affect the benefit you receive when you make a claim. Some cover benefit limits or sublimits may be less than your total excess and therefore the insurer may not contribute anything to your claim.';
      case '$1000':
        return 'If you choose a higher excess it can reduce your premiums but it will affect the benefit you receive when you make a claim. Some cover benefit limits or sublimits may be less than your total excess and therefore the insurer may not contribute anything to your claim.';
      case '$1,000':
        return 'If you choose a higher excess it can reduce your premiums but it will affect the benefit you receive when you make a claim. Some cover benefit limits or sublimits may be less than your total excess and therefore the insurer may not contribute anything to your claim.';
      case '$2,000':
        return 'If you choose a higher excess it can reduce your premiums but it will affect the benefit you receive when you make a claim. Some cover benefit limits or sublimits may be less than your total excess and therefore the insurer may not contribute anything to your claim.';
      case '$3,000':
        return 'If you choose a higher excess it can reduce your premiums but it will affect the benefit you receive when you make a claim. Some cover benefit limits or sublimits may be less than your total excess and therefore the insurer may not contribute anything to your claim.';
      case '$4,000':
        return 'If you choose a higher excess it can reduce your premiums but it will affect the benefit you receive when you make a claim. Some cover benefit limits or sublimits may be less than your total excess and therefore the insurer may not contribute anything to your claim.';
      case '$5,000':
        return 'If you choose a higher excess it can reduce your premiums but it will affect the benefit you receive when you make a claim. Some cover benefit limits or sublimits may be less than your total excess and therefore the insurer may not contribute anything to your claim.';
      case '$6,000':
        return 'If you choose a higher excess it can reduce your premiums but it will affect the benefit you receive when you make a claim. Some cover benefit limits or sublimits may be less than your total excess and therefore the insurer may not contribute anything to your claim.';
      case '$7,000':
        return 'If you choose a higher excess it can reduce your premiums but it will affect the benefit you receive when you make a claim. Some cover benefit limits or sublimits may be less than your total excess and therefore the insurer may not contribute anything to your claim.';
      case '$8,000':
        return 'If you choose a higher excess it can reduce your premiums but it will affect the benefit you receive when you make a claim. Some cover benefit limits or sublimits may be less than your total excess and therefore the insurer may not contribute anything to your claim.';
      default:
        return;
    }
  }

  onRadioExcessClick(event: any, label: string) {
    this.selectedExcessLabel = label;
    const data: any = {
      optionName: 'excess',
      excess: label,
      isCruise: this.selectedCover?.selectedPrice?.isCruise,
      isSki: this.selectedCover?.selectedPrice?.isSki,
    };
    this.optionChange.emit(data);
  }

  onRadioCruiseClick(event: any, label: string) {
    this.selectedCruiseLabel = label;
    const isCruise = label === 'cruise_yes' ? true : false;
    const isSki = this.selectedCover?.selectedPrice?.isSki;
    const data = this.getSelectedOption('cruise', isCruise, isSki);
    this.optionChange.emit(data);
  }

  onRadioSportsClick(event: any, label: string) {
    this.selectedSportsLabel = label;
    const isCruise = this.selectedCover?.selectedPrice?.isCruise;
    const isSki = label === 'sports_yes' ? true : false;
    const data = this.getSelectedOption('sports', isCruise, isSki);
    this.optionChange.emit(data);
  }
  getSelectedOption(optionName: string, isCruise: boolean, isSki: boolean) {
    const data: any = {
      optionName: optionName,
      excess: this.selectedCover?.selectedPrice?.excess,
      isCruise: isCruise,
      isSki: isSki,
    };
    return data;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateTooltipVisibility();
  }

  toggleTooltip() {
    this.isTooltipVisible = !this.isTooltipVisible;
  }

  private updateTooltipVisibility() {
    this.isTooltipVisible = window.innerWidth < 768;
    this.isTooltipVisibleSki = window.innerWidth < 768;
    this.isTooltipVisibleCruise = window.innerWidth < 768;
  }

  sendAdobeIneractionEvent(field: string) {
    this.adobeAnalyticsService.sendInteractWithFields(field);
  }
}
