import { Component, OnDestroy, OnInit } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { Router, RouterLink } from '@angular/router';
import { RacvFooterComponent } from '../../components/racv-footer/racv-footer.component';
import { RacvHeaderComponent } from '../../components/racv-header/racv-header.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StepBarComponent } from '../../components/step-bar/step-bar.component';
import { BraintreeWidgetComponent } from '../../components/braintree-widget/braintree-widget.component';
import { LocalCacheService } from '../../services/localCache.service';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { MomentPipe } from '../../utils/moment.pipe';
import { GlobalService } from '../../services/global.service';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { NgxBraintreeService } from '../../services/ngx-braintree.service';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { AdobeAnalyticsService } from '../../services/adobe.analytics.service';
@Component({
  selector: 'app-step-four',
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    CommonModule,
    RacvFooterComponent,
    RacvHeaderComponent,
    ReactiveFormsModule,
    RadioButtonModule,
    StepBarComponent,
    BraintreeWidgetComponent,
    MomentPipe,
    DialogModule,
    ButtonModule,
    InputTextModule,
    MessageModule,
  ],
  providers: [provideNativeDateAdapter(), NgxBraintreeService],
  templateUrl: './step-four.component.html',
  styleUrl: './step-four.component.scss',
})
export class StepFourComponent implements OnInit, OnDestroy {
  policyDetails: any = {};
  selectedCover: any;
  quoteEnquiry: any;
  quoteDetail: any;
  activatedClub: string = '';
  isOnlinePayment = 'Yes';
  paymentRecieptNo = '';
  paymentOptions = [
    { label: 'Online Payment', value: 'Yes' },
    { label: 'Cash Payment', value: 'No' },
  ];
  completePurchaseObject: any;
  agentGuid: any;
  branchGuid: any;
  paymentForm: FormGroup;

  constructor(
    private localCacheService: LocalCacheService,
    private router: Router,
    private globalService: GlobalService,
    private service: NgxBraintreeService,
    private fb: FormBuilder,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {
    this.paymentForm = this.fb.group({
      paymentRecieptNo: ['', [Validators.maxLength(30)]],
    });
  }
  displayConfirmation: boolean = false;

  ngOnInit(): void {
    if (!this.globalService.checkAndValidatePage(4, false)) {
      this.router.navigate(['/error']);
      return;
    }
    this.activatedClub = environment.autoClub;
    this.loadData();

    this.agentGuid = this.localCacheService.getSessionStorage('agentGuid');
    this.branchGuid = this.localCacheService.getSessionStorage('branchGuid');

    if (!this.quoteDetail) {
      this.router.navigate(['/']);
      return;
    }
    this.adobeAnalyticsService.sendPayment();
  }

  ngOnDestroy(): void {}

  loadData() {
    this.quoteDetail =
      this.localCacheService.getSessionStorage('quoteDetail') || {};
    this.quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry') || {};
    this.selectedCover =
      this.localCacheService.getSessionStorage('selectedCoverDetail') || {};
    this.policyDetails = {
      quoteNumber: this.quoteDetail.quoteNumber,
      destination: this.countryNames,
      isSingleTrip: this.quoteEnquiry.isSingleTrip,
      promoCode: this.quoteDetail.promoCode,
      departDate: moment(this.quoteEnquiry.depart).format("DD MMMM' YYYY"),
      returnDate: moment(this.quoteEnquiry.return).format("DD MMMM' YYYY"),
      duration: this.calculateDaysBetweenDates(),
      ageOfTravellers: this.displayTravellersAge(),
      nameOfTravelers: this.displayTravelersName(),
      noOfDependents: this.quoteDetail.dependentsCount,
      productType: this.quoteDetail.productType,
      productAlias: this.quoteDetail.productAlias,
      totalCoverPolicy: this.quoteDetail.premiums.product,
      isCruise: this.quoteDetail.isCruise === true ? 'Yes' : 'No',
      isSki: this.quoteDetail.isSking === true ? 'Yes' : 'No',
      policyExcess: this.quoteDetail.premiums.excess,
      cruiseExpense: this.quoteDetail.premiums.cruise,
      skiExpense: this.quoteDetail.premiums.skiing,
      promoCodeDisc: this.quoteDetail.premiums.promoCodeDiscount,
      memberDisc: this.quoteDetail.premiums.membershipDiscount,
      additionalMedicalExpense: this.quoteDetail.premiums.medical,
      gst: this.quoteDetail.premiums.gst,
      stampDuty: this.quoteDetail.premiums.stampDuty,
      totalExpense: this.quoteDetail.premiums.totalCost,
    };
  }

  get countryNames(): string {
    return this.quoteEnquiry.travelDestination
      .map((country: any) => country.countryName)
      .join(', ');
  }

  calculateDaysBetweenDates() {
    const depDate = moment(this.quoteEnquiry.return);
    const rtnDate = moment(this.quoteEnquiry.depart);
    return depDate.diff(rtnDate, 'days');
  }

  displayTravellersAge() {
    if (this.quoteEnquiry.age2?.length > 0) {
      return this.quoteEnquiry.age1 + ',' + this.quoteEnquiry.age2;
    } else {
      return this.quoteEnquiry.age1;
    }
  }
  displayTravelersName() {
    if (this.quoteDetail?.travelers && this.quoteDetail.travelers.length > 0) {
      const travelerNames: string[] = [];
      const dependentNames: string[] = [];
      this.quoteDetail?.travelers.forEach((element: any) => {
        if (element.role != 'dependent') {
          travelerNames.push(`${element.firstName} ${element.lastName}`);
        } else {
          dependentNames.push(`${element.firstName} ${element.lastName}`);
        }
      });
      const names = `${travelerNames.join(', ')}\n${dependentNames.join(', ')}`;
      return names;
    }
    return '';
  }
  showConfirm() {
    this.displayConfirmation = true;
  }
  cancelCashPayment() {
    this.displayConfirmation = false;
    this.router.navigate(['/quotes-steps-three']);
  }
  onPaymentTypeChange(type: string) {
    this.isOnlinePayment = type == 'Online' ? 'Yes' : 'No';
  }
  pay(): void {
    if (!this.paymentForm.valid) {
      this.paymentForm.markAllAsTouched();
      return;
    }

    this.completePurchaseObject = {
      quoteId: this.quoteDetail?.quoteId,
      autoClub: this.quoteDetail?.autoClub,
      amount: this.policyDetails.totalExpense,
      cashReceiptNumber: this.paymentForm.value.paymentRecieptNo,
      nonce: '',
      methodType: 'Cash',
    };

    this.completePurchaseObject.agentInfo = {
      agentGuid: this.agentGuid,
      branchGuid: this.branchGuid,
    };
    //Make server call to settle the payment
    this.service
      .createPurchase('', this.completePurchaseObject)
      .subscribe((result: any) => {
        var strResult = JSON.stringify(result);
        console.log(strResult);
        this.localCacheService.saveSessionStorage('paymentInfo', strResult);
        var sendPolicyObj: any = {
          movementGuid: result.movementGuid,
          movementType: 'NB',
        };
        this.service.sendPolicy(sendPolicyObj).subscribe((response) => {});
        this.globalService.checkAndValidatePage(4, true);
        this.router.navigate(['payment-confirmation']);
      });
  }

  sendAdobeNeedCallClickEvent() {
    this.adobeAnalyticsService.sendNeedCallClick();
  }
}
