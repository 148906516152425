<p-card class="text-start choose-option-card" header="Choose Your Options">
  <div class="row">
    <!-- Choose Your Excess -->
    <div class="col-sm-4">
      <div class="mb-3 text-start">
        <label class="col-form-label d-block font-20 mb-2"
          >Choose Your Excess~</label
        >

        <button
          *ngFor="let excess of selectedCover.excessValues"
          type="button"
          class="btn szbtn btn-lg btn-outline-info px-4 moboptbtn mb-1"
          [ngClass]="{ 'selected-label': selectedExcessLabel === excess }"
          pButton
          (click)="
            onRadioExcessClick($event, excess);
            sendAdobeIneractionEvent('Excess' + excess)
          "
          [pTooltip]="onExcessTooltip(excess)"
          tooltipPosition="right"
          tooltipStyleClass="custom-tooltip"
        >
          {{ excess }}
        </button>
      </div>
    </div>
    <!-- Going on Cruise -->

    <div
      class="col-sm-4"
      *ngIf="
        selectedCover?.selectedPrice?.productType !== 'Domestic' &&
        selectedCover?.selectedPrice?.productType !== 'Rental Base'
      "
    >
      <div class="mb-3 text-start" *ngIf="quoteEnquiry?.isSingleTrip">
        <label for="" class="col-form-label d-block font-20 mb-2">
          Going on a Cruise?~
        </label>
        <button
          type="button"
          class="btn btn-lg btn-outline-info px-4 moboptbtn szbtn"
          [ngClass]="{ 'selected-label': selectedCruiseLabel === 'cruise_yes' }"
          pButton
          (click)="
            onRadioCruiseClick($event, 'cruise_yes');
            sendAdobeIneractionEvent('cruise_yes')
          "
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-lg btn-outline-info px-4 moboptbtn szbtn"
          [ngClass]="{ 'selected-label': selectedCruiseLabel === 'cruise_no' }"
          pButton
          (click)="
            onRadioCruiseClick($event, 'cruise_no');
            sendAdobeIneractionEvent('cruise_no')
          "
          [disabled]="isCruise"
        >
          No
        </button>

        <span class="d-inline-block ttbg lightcbg" tabindex="0">
          <ng-container *ngIf="isTooltipVisibleCruise; else toolTipOnHover">
            <div (click)="toggleTooltip()" class="tooltip-container">
              <img
                src="assets/images/racv/info.png"
                class="tooltipc custom-tooltip"
                width="20"
              />
              <ng-container *ngIf="isTooltipVisibleCruise">
                <ng-container *ngTemplateOutlet="cruiseTooltip"></ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #toolTipOnHover>
            <img
              src="assets/images/racv/info.png"
              class="tooltipc"
              width="20"
              [pTooltip]="cruiseTooltip"
              tooltipPosition="right"
              tooltipStyleClass="custom-tooltip"
            />
          </ng-template>
        </span>
      </div>
      <div class="mb-3 text-start" *ngIf="!quoteEnquiry?.isSingleTrip">
        <label for="" class="col-form-label d-block font-20 mb-2">
          Going on a Cruise?~
        </label>
        <button
          type="button"
          class="btn btn-lg btn-outline-info px-4 moboptbtn szbtn"
          [ngClass]="{ 'selected-label': selectedCruiseLabel === 'cruise_yes' }"
          pButton
          (click)="
            onRadioCruiseClick($event, 'cruise_yes');
            sendAdobeIneractionEvent('cruise_yes')
          "
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-lg btn-outline-info px-4 moboptbtn szbtn"
          [ngClass]="{ 'selected-label': selectedCruiseLabel === 'cruise_no' }"
          pButton
          [disabled]="isCruise"
          (click)="
            onRadioCruiseClick($event, 'cruise_no');
            sendAdobeIneractionEvent('cruise_no')
          "
        >
          No
        </button>

        <span class="d-inline-block ttbg lightcbg" tabindex="0">
          <ng-container *ngIf="isTooltipVisibleCruise; else toolTipOnHover">
            <div (click)="toggleTooltip()" class="tooltip-container">
              <img
                src="assets/images/racv/info.png"
                class="tooltipc custom-tooltip"
                width="20"
              />
              <ng-container *ngIf="isTooltipVisibleCruise">
                <ng-container *ngTemplateOutlet="cruiseTooltip"></ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #toolTipOnHover>
            <img
              src="assets/images/racv/info.png"
              class="tooltipc"
              width="20"
              [pTooltip]="cruiseTooltip"
              tooltipPosition="right"
              tooltipStyleClass="custom-tooltip"
            />
          </ng-template>
        </span>
      </div>
    </div>
    <!-- Ski/Winter Sports -->
    <div
      class="col-sm"
      *ngIf="
        isSki &&
        selectedCover?.selectedPrice?.productType !== 'Basics' &&
        selectedCover?.selectedPrice?.productType !== 'Rental Base'
      "
    >
      <div class="mb-3 text-start" *ngIf="quoteEnquiry?.isSingleTrip">
        <label for="" class="col-form-label d-block font-20 mb-2">
          Ski/Winter Sports?~
        </label>
        <button
          type="button"
          class="btn btn-lg btn-outline-info px-4 moboptbtn szbtn"
          [ngClass]="{ 'selected-label': selectedSportsLabel === 'sports_yes' }"
          pButton
          (click)="
            onRadioSportsClick($event, 'sports_yes');
            sendAdobeIneractionEvent('sports_yes')
          "
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-lg btn-outline-info px-4 moboptbtn szbtn"
          [ngClass]="{ 'selected-label': selectedSportsLabel === 'sports_no' }"
          pButton
          (click)="
            onRadioSportsClick($event, 'sports_no');
            sendAdobeIneractionEvent('sports_no')
          "
        >
          No
        </button>

        <span class="d-inline-block ttbg lightcbg" tabindex="0">
          <ng-container *ngIf="isTooltipVisibleSki; else toolTipOnHover">
            <div (click)="toggleTooltip()" class="tooltip-container">
              <img
                src="assets/images/racv/info.png"
                class="tooltipc withbg"
                width="20"
              />
              <ng-container *ngIf="isTooltipVisibleSki">
                <ng-container
                  *ngTemplateOutlet="skiWinterTooltip"
                ></ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #toolTipOnHover>
            <img
              src="assets/images/racv/info.png"
              class="tooltipc withbg"
              width="20"
              [pTooltip]="skiWinterTooltip"
              tooltipPosition="right"
              tooltipStyleClass="travel-destination-tooltip"
            />
          </ng-template>
        </span>
      </div>
      <div class="mb-3 text-start" *ngIf="!quoteEnquiry?.isSingleTrip">
        <label for="" class="col-form-label d-block font-20 mb-2">
          Ski/Winter Sports?~
        </label>

        <button
          type="button"
          class="btn btn-lg btn-outline-info px-4 moboptbtn szbtn"
          [ngClass]="{ 'selected-label': selectedSportsLabel === 'sports_yes' }"
          pButton
          (click)="
            onRadioSportsClick($event, 'sports_yes');
            sendAdobeIneractionEvent('sports_yes')
          "
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-lg btn-outline-info px-4 moboptbtn szbtn"
          [ngClass]="{ 'selected-label': selectedSportsLabel === 'sports_no' }"
          pButton
          (click)="
            onRadioSportsClick($event, 'sports_no');
            sendAdobeIneractionEvent('sports_no')
          "
        >
          No
        </button>

        <span class="d-inline-block ttbg lightcbg" tabindex="0">
          <ng-container *ngIf="isTooltipVisibleSki; else toolTipOnHover">
            <div (click)="toggleTooltip()" class="tooltip-container">
              <img
                src="assets/images/racv/info.png"
                class="tooltipc withbg"
                width="20"
              />
              <ng-container *ngIf="isTooltipVisibleSki">
                <ng-container
                  *ngTemplateOutlet="skiWinterTooltip"
                ></ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #toolTipOnHover>
            <img
              src="assets/images/racv/info.png"
              class="tooltipc withbg"
              width="20"
              [pTooltip]="skiWinterTooltip"
              tooltipPosition="right"
              tooltipStyleClass="travel-destination-tooltip"
            />
          </ng-template>
        </span>
      </div>
    </div>
  </div>
  <div class="additional-option">
    ~ If you change the Cover Type selected, you will need to re-select your
    Options
  </div>
</p-card>

<ng-template #skiWinterTooltip>
  <div
    [ngClass]="
      isTooltipVisibleSki ? { 'tooltip-content': true, show: true } : ''
    "
    *ngIf="selectedCover?.selectedPrice?.productType !== 'Domestic'"
  >
    You must purchase the Ski and Winter Sports option in order to be covered
    for Overseas Medical and Hospital expenses that are incurred following an
    Accident that takes place whilst participating in Skiing or a Winter Sport.
    Not all Winter Sports are covered and these are explained in the PDS.
  </div>
  <div
    [ngClass]="
      isTooltipVisibleSki ? { 'tooltip-content': true, show: true } : ''
    "
    *ngIf="selectedCover?.selectedPrice?.productType === 'Domestic'"
  >
    You must purchase the Ski and Winter Sports option to be covered for
    expenses related to snow ski equipment hire, ski pack, bad weather,
    avalanche, and lift pass. However, no medical or hospital expenses are
    covered. Please note that not all Winter Sports are covered, these are
    explained in the PDS.
  </div>
</ng-template>

<ng-template #cruiseTooltip>
  <div
    [ngClass]="
      isTooltipVisibleCruise ? { 'tooltip-content': true, show: true } : ''
    "
  >
    For more information on what types of cruises this cover applies to and to
    assist you in selecting the cover that is appropriate for your cruise,
    please select the Cruise page link at the top of this page.
  </div>
</ng-template>
