import { Injectable } from '@angular/core';
import { LocalCacheService } from './localCache.service';
import { GlobalService } from './global.service';
import { Title } from '@angular/platform-browser';
import { Utility } from '../utils/utils';

declare global {
  interface Window {
    digitalData: any;
  }
}
@Injectable({
  providedIn: 'root',
})
export class AdobeAnalyticsService {
  constructor(
    private localCacheService: LocalCacheService,
    private titleService: Title,
    private globalService: GlobalService,
  ) {}

  sendBasePageData(path: string) {
    // BC01
    this.safeExecute(() => {
      console.log('sendBasePageData', path);
      let pageName = this.titleService.getTitle();
      switch (path) {
        case '':
          pageName = 'get a quote';
          break;
        case '/quotes-steps-one':
          pageName = 'select cover';
          break;
        case '/quotes-steps-two':
          pageName = 'enter details';
          break;
        case '/quotes-steps-three':
          pageName = 'confirm details';
          break;
        case '/quotes-steps-four':
          pageName = 'payment';
          break;
        case '/payment-confirmation':
          pageName = 'purchase confirmation';
          break;
        default:
          break;
      }
      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      const pageInfo = {
        abort: 'false',
        pageName: pageName,
      };
      const userInfo = {
        authState: quoteDetail?.membershipNumber ? 'auth' : 'unauth',
        userID: quoteDetail?.membershipNumber ?? '',
      };
      window.digitalData.page = {};
      window.digitalData.page.pageInfo = pageInfo;
      window.digitalData.user = {};
      window.digitalData.user.userInfo = userInfo;
    });
  }

  sendGetaQuoteFormEvent() {
    // FN01
    this.safeExecute(() => {
      const pageInfo = {
        abort: 'false',
        pageName: 'get a quote',
      };
      window.digitalData.page = {};
      window.digitalData.page.pageInfo = pageInfo;
      window.digitalData.event.push({ eventAction: 'page-navigate' });

      const formInfo = {
        formName: 'travel insurance application form',
        stage: 'start',
        stepName: 'get a quote',
        stepNumber: '1',
        formType: 'application',
      };
      window.digitalData.form = {};
      window.digitalData.form.formInfo = {};
      window.digitalData.form.formInfo = formInfo;
      window.digitalData.event.push({ eventAction: 'form-navigate' });
    });
  }

  sendSelectCover() {
    // FN02
    this.safeExecute(() => {
      const pageInfo = {
        abort: 'false',
        pageName: 'select cover',
      };
      window.digitalData.page = {};
      window.digitalData.page.pageInfo = pageInfo;
      window.digitalData.event.push({ eventAction: 'page-navigate' });

      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      const countryString = this.getDestinationsString(
        quoteDetail?.destinations,
      );
      const ageString = this.getAgesString(quoteDetail?.ages);
      const formInfo = {
        formName: 'travel insurance application form',
        stage: '',
        stepName: 'select cover',
        stepNumber: '2',
        formType: 'application',
        formField: {
          country: countryString,
          countryQuantity: quoteDetail?.destinations?.length,
          age: ageString,
        },
      };
      const productInfo = {
        productType: quoteDetail?.isSingleTrip
          ? 'single trip'
          : 'annual multi trip',
        productName: quoteDetail?.productAlias,
      };
      window.digitalData.form = {};
      window.digitalData.form.formInfo = {};
      window.digitalData.form.formInfo = formInfo;
      window.digitalData.product = {};
      window.digitalData.product.productInfo = {};
      window.digitalData.product.productInfo = productInfo;
      window.digitalData.event.push({ eventAction: 'form-navigate' });
    });
  }

  sendEnterDetails() {
    // FN03
    this.safeExecute(() => {
      const pageInfo = {
        abort: 'false',
        pageName: 'enter details',
      };
      window.digitalData.page = {};
      window.digitalData.page.pageInfo = pageInfo;
      window.digitalData.event.push({ eventAction: 'page-navigate' });

      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      const countryString = this.getDestinationsString(
        quoteDetail?.destinations,
      );
      const ageString = this.getAgesString(quoteDetail?.ages);
      const formInfo = {
        formName: 'travel insurance application form',
        stage: '',
        stepName: 'enter details',
        stepNumber: '3',
        formType: 'application',
        formField: {
          country: countryString,
          countryQuantity: quoteDetail?.destinations?.length,
          age: ageString,
        },
      };
      const productInfo = {
        productType: quoteDetail?.isSingleTrip
          ? 'single trip'
          : 'annual multi trip',
        productName: quoteDetail?.productAlias,
      };
      window.digitalData.form = {};
      window.digitalData.form.formInfo = {};
      window.digitalData.form.formInfo = formInfo;
      window.digitalData.product = {};
      window.digitalData.product.productInfo = {};
      window.digitalData.product.productInfo = productInfo;
      window.digitalData.event.push({ eventAction: 'form-navigate' });
    });
  }

  sendConfirmDetails() {
    // FN04
    this.safeExecute(() => {
      const pageInfo = {
        abort: 'false',
        pageName: 'confirm details',
      };
      window.digitalData.page = {};
      window.digitalData.page.pageInfo = pageInfo;
      window.digitalData.event.push({ eventAction: 'page-navigate' });

      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      const countryString = this.getDestinationsString(
        quoteDetail?.destinations,
      );
      const ageString = this.getAgesString(quoteDetail?.ages);
      const formInfo = {
        formName: 'travel insurance application form',
        stage: '',
        stepName: 'confirm details',
        stepNumber: '4',
        formType: 'application',
        formField: {
          country: countryString,
          countryQuantity: quoteDetail?.destinations?.length,
          age: ageString,
        },
      };
      const productInfo = {
        productType: quoteDetail?.isSingleTrip
          ? 'single trip'
          : 'annual multi trip',
        productName: quoteDetail?.productAlias,
      };
      window.digitalData.form = {};
      window.digitalData.form.formInfo = {};
      window.digitalData.form.formInfo = formInfo;
      window.digitalData.product = {};
      window.digitalData.product.productInfo = {};
      window.digitalData.product.productInfo = productInfo;
      window.digitalData.event.push({ eventAction: 'form-navigate' });
    });
  }

  sendPayment() {
    // FN05
    this.safeExecute(() => {
      const pageInfo = {
        abort: 'false',
        pageName: 'payment',
      };
      window.digitalData.page = {};
      window.digitalData.page.pageInfo = pageInfo;
      window.digitalData.event.push({ eventAction: 'page-navigate' });

      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      const countryString = this.getDestinationsString(
        quoteDetail?.destinations,
      );
      const ageString = this.getAgesString(quoteDetail?.ages);
      const formInfo = {
        formName: 'travel insurance application form',
        stage: '',
        stepName: 'payment',
        stepNumber: '5',
        formType: 'application',
        formField: {
          country: countryString,
          countryQuantity: quoteDetail?.destinations?.length,
          age: ageString,
          marketingConsent: quoteDetail?.marketingConsent ? 'yes' : 'no',
        },
      };
      const productInfo = {
        productType: quoteDetail?.isSingleTrip
          ? 'single trip'
          : 'annual multi trip',
        productName: quoteDetail?.productAlias,
      };
      window.digitalData.form = {};
      window.digitalData.form.formInfo = {};
      window.digitalData.form.formInfo = formInfo;
      window.digitalData.product = {};
      window.digitalData.product.productInfo = {};
      window.digitalData.product.productInfo = productInfo;
      window.digitalData.event.push({ eventAction: 'form-navigate' });
    });
  }

  snedPurchaseConfirmation() {
    // FN06
    this.safeExecute(() => {
      const pageInfo = {
        abort: 'false',
        pageName: 'purchase confirmation',
      };
      window.digitalData.page = {};
      window.digitalData.page.pageInfo = pageInfo;
      window.digitalData.event.push({ eventAction: 'page-navigate' });

      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      const countryString = this.getDestinationsString(
        quoteDetail?.destinations,
      );
      const ageString = this.getAgesString(quoteDetail?.ages);
      const agentGuid = this.globalService.getQueryParamInfo('AgentGuid');
      const formInfo = {
        formName: 'travel insurance application form',
        stage: 'complete',
        stepName: 'purchase confirmation',
        stepNumber: '6',
        formType: 'application',
        reference: agentGuid,
        formField: {
          country: countryString,
          countryQuantity: quoteDetail?.destinations?.length,
          age: ageString,
          marketingConsent: quoteDetail?.marketingConsent ? 'yes' : 'no',
          productPrice: quoteDetail?.premiums?.totalCost,
          ageDependents: this.getDependentsAgesString(quoteDetail?.travelers),
        },
      };
      const productInfo = {
        productType: quoteDetail?.isSingleTrip
          ? 'single trip'
          : 'annual multi trip',
        productName: quoteDetail?.productAlias,
      };
      window.digitalData.form = {};
      window.digitalData.form.formInfo = {};
      window.digitalData.form.formInfo = formInfo;
      window.digitalData.product = {};
      window.digitalData.product.productInfo = {};
      window.digitalData.product.productInfo = productInfo;
      window.digitalData.event.push({ eventAction: 'form-navigate' });
    });
  }

  sendInteractWithFields(fieldName: string) {
    // FI01
    this.safeExecute(() => {
      const formInfo = {
        lastFieldInteracted: fieldName,
      };
      window.digitalData.form = {};
      window.digitalData.form.formInfo = {};
      window.digitalData.form.formInfo = formInfo;
      window.digitalData.event.push({ eventAction: 'form-interact' });
    });
  }

  sendPromoCode(code: string) {
    // FM01
    this.safeExecute(() => {
      const formInfo = {
        messageType: 'success message',
        messageDescription: `${code} | promo code successfully applied `,
      };
      window.digitalData.form = {};
      window.digitalData.form.formInfo = {};
      window.digitalData.form.formInfo = formInfo;
      window.digitalData.event.push({ eventAction: 'form-interact' });
    });
  }

  sendAlertMessage(alertMessage: string) {
    // FM02
    this.safeExecute(() => {
      const formInfo = {
        messageType: 'alert message',
        messageDescription: alertMessage,
      };
      window.digitalData.form = {};
      window.digitalData.form.formInfo = {};
      window.digitalData.form.formInfo = formInfo;
      window.digitalData.event.push({ eventAction: 'form-interact' });
    });
  }

  sendErrorMessages(errorMessage: string) {
    // FE01
    this.safeExecute(() => {
      const formInfo = {
        error: errorMessage,
        errorType: 'inline error',
      };
      console.log('sendErrorMessages', formInfo);
      window.digitalData.form = {};
      window.digitalData.form.formInfo = {};
      window.digitalData.form.formInfo = formInfo;
      window.digitalData.event.push({ eventAction: 'form-interact' });
    });
  }

  sendButtonBarClick(buttonName: string) {
    // SI01
    this.safeExecute(() => {
      const siteInteraction = {
        type: 'button',
        location: 'sticky bottom bar',
        description: buttonName,
      };
      window.digitalData.siteInteraction = {};
      window.digitalData.siteInteraction = siteInteraction;
      window.digitalData.event.push({ eventAction: 'site-interact' });
    });
  }

  sendSideBarClick(buttonName: string) {
    // SI02
    this.safeExecute(() => {
      const siteInteraction = {
        type: 'button',
        location: 'sticky side bar',
        description: buttonName,
      };
      window.digitalData.siteInteraction = {};
      window.digitalData.siteInteraction = siteInteraction;
      window.digitalData.event.push({ eventAction: 'site-interact' });
    });
  }

  sendQuoteBarClick() {
    // SI03
    this.safeExecute(() => {
      const siteInteraction = {
        type: 'button',
        location: 'sticky quote bar',
        description: 'Update Trip Details',
      };
      window.digitalData.siteInteraction = {};
      window.digitalData.siteInteraction = siteInteraction;
      window.digitalData.event.push({ eventAction: 'site-interact' });
    });
  }

  sendNeedCallClick() {
    // SI04
    this.safeExecute(() => {
      const siteInteraction = {
        type: 'button',
        location: 'Need help? Call us on:',
        description: '13 13 29',
      };
      window.digitalData.siteInteraction = {};
      window.digitalData.siteInteraction = siteInteraction;
      window.digitalData.event.push({ eventAction: 'site-interact' });
    });
  }

  sendEditButtonClick(sectionName: string) {
    // SI05
    this.safeExecute(() => {
      const siteInteraction = {
        type: 'button',
        location: `confirm details | ${sectionName}`,
        description: 'edit',
      };
      window.digitalData.siteInteraction = {};
      window.digitalData.siteInteraction = siteInteraction;
      window.digitalData.event.push({ eventAction: 'site-interact' });
    });
  }

  sendAccordionClick(description: string) {
    // SI06
    this.safeExecute(() => {
      const siteInteraction = {
        type: 'accordion',
        location: 'accordion',
        description: description,
      };
      window.digitalData.siteInteraction = {};
      window.digitalData.siteInteraction = siteInteraction;
      window.digitalData.event.push({ eventAction: 'site-interact' });
    });
  }

  sendHeaderClick(headerName: string) {
    // SI07
    this.safeExecute(() => {
      const siteInteraction = {
        type: 'link',
        location: 'header',
        description: headerName,
      };
      window.digitalData.siteInteraction = {};
      window.digitalData.siteInteraction = siteInteraction;
      window.digitalData.event.push({ eventAction: 'site-interact' });
    });
  }
  sendFooterClick(footerName: string) {
    // SI08
    this.safeExecute(() => {
      const siteInteraction = {
        type: 'link',
        location: 'footer',
        description: footerName,
      };
      window.digitalData.siteInteraction = {};
      window.digitalData.siteInteraction = siteInteraction;
      window.digitalData.event.push({ eventAction: 'site-interact' });
    });
  }

  sendGetaQuoteClick() {
    // SI09
    this.safeExecute(() => {
      const siteInteraction = {
        type: 'button',
        location: 'step 1:get a quote',
        description: 'get a quote',
      };
      window.digitalData.siteInteraction = {};
      window.digitalData.siteInteraction = siteInteraction;
      window.digitalData.event.push({ eventAction: 'site-interact' });
    });
  }

  getDestinationsString(destinations: any) {
    let destinationString = '';
    destinations.forEach((destination: any, index: number) => {
      destinationString += destination.countryName;
      if (index < destinations.length - 1) {
        destinationString += ':';
      }
    });
    return destinationString;
  }

  getAgesString(ages: any) {
    let ageString = '';
    ages.forEach((age: any, index: number) => {
      ageString += age.toString();
      if (index < ages.length - 1) {
        ageString += ':';
      }
    });
    return ageString;
  }

  getDependentsAgesString(travelers: any) {
    let dependents: any = travelers?.filter(
      (traveler: any) => traveler.role === 'dependent',
    );
    let ageString = '';
    dependents?.forEach((dependent: any, index: number) => {
      ageString += Utility.ageFromDDMMYYYFormat(dependent.dateOfBirth);
      if (index < dependents.length - 1) {
        ageString += ':';
      }
    });
    return ageString;
  }

  safeExecute(fn: any) {
    try {
      fn();
    } catch (error) {
      console.error('An error occurred in the Google analytics code:', error);
    }
  }
}
