import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RacvHeaderComponent } from '../../components/racv-header/racv-header.component';
import { RacvFooterComponent } from '../../components/racv-footer/racv-footer.component';
import { GlobalService } from '../../services/global.service';
@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [RacvHeaderComponent, RacvFooterComponent],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss'
})
export class ErrorPageComponent {
  public errorMessage: string | any;
  public returnPage: string | any;
  constructor(private router: Router, private globalService: GlobalService) { }

  ngOnInit(): void {
    this.errorMessage = '';
    sessionStorage.clear();
    this.globalService.sendErrorPage('404 not found', this.router.url);
  }

  goToStepOne(): void {
      this.router.navigate(['']);
  }
}
