<div class="step-three-page-container theme-steps">
  <racv-app-header></racv-app-header>

  <!-- Step Count  -->
  <app-step-bar
    [customStyles]="{ width: '66%' }"
    [activeStep]="'step3'"
  ></app-step-bar>

  <section class="quote selection stepthree">
    <div class="container mt-5 p-4 p-md-3">
      <div class="row justify-content-center">
        <div class="col-md-11 col-12 borderpre p-2 p-md-4">
          <div class="row justify-content-center">
            <div class="col-md-12 p-3">
              <div class="summary-title d-flex justify-content-between">
                <h3>Traveller Summary</h3>
                <h4>
                  <a
                    href="#"
                    class="text-info"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#traveller_summery_edit"
                    aria-controls=""
                    (click)="sendAdobeEditButtonClickEvent('Traveller Summary')"
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </a>
                </h4>
              </div>
            </div>
            <div class="col-md-12">
              <div class="summary-result bg-light p-md-3 p-2">
                <div class="d-none d-lg-block">
                  <table class="w-100">
                    <!-- Entry Start -->
                    <tr>
                      <td colspan="5"><b>Adult 1(Primary Traveller)</b></td>
                    </tr>
                    <tr class="text-racv">
                      <td class="fname">First Name</td>
                      <td class="lname">Last Name</td>
                      <td class="age">Age</td>
                      <td class="dob">Date of Birth</td>
                      <td class="amc">Additional Medical Cover</td>
                      <td class="mc">Medical Conditions</td>
                    </tr>
                    <tr class="fw-bold">
                      <td>{{ travelSummary.adult1.firstName }}</td>
                      <td>{{ travelSummary.adult1.lastName }}</td>
                      <td>
                        {{ travelSummary.adult1.dateOfBirth | ageFormat }}
                      </td>
                      <td>{{ travelSummary.adult1.dateOfBirth }}</td>
                      <td>
                        $ {{ travelSummary.adult1.medicalCover | amountFormat }}
                      </td>
                      <td>
                        {{ travelSummary.adult1.medicalConditions ?? '-' }}
                      </td>
                    </tr>
                    <!-- Entry Start -->
                    <ng-container *ngIf="hasAdult2">
                      <tr>
                        <td colspan="5" class="pt-3"><b>Adult 2</b></td>
                      </tr>
                      <tr class="text-racv">
                        <td class="fname">First Name</td>
                        <td class="lname">Last Name</td>
                        <td class="age">Age</td>
                        <td class="dob">Date of Birth</td>
                        <td class="amc">Additional Medical Cover</td>
                        <td class="mc">Medical Conditions</td>
                      </tr>
                      <tr class="fw-bold">
                        <td>{{ travelSummary.adult2.firstName }}</td>
                        <td>{{ travelSummary.adult2.lastName }}</td>
                        <td>
                          {{ travelSummary.adult2.dateOfBirth | ageFormat }}
                        </td>
                        <td>{{ travelSummary.adult2.dateOfBirth }}</td>
                        <td>
                          $
                          {{ travelSummary.adult2.medicalCover | amountFormat }}
                        </td>
                        <td>
                          {{ travelSummary.adult2.medicalConditions ?? '-' }}
                        </td>
                      </tr>
                    </ng-container>
                    <!-- Entry Start -->
                    <ng-container
                      *ngFor="let dependent of dependentsInfo; let i = index"
                    >
                      <tr>
                        <td colspan="5" class="pt-3">
                          <b>Dependent {{ i + 1 }}</b>
                        </td>
                      </tr>
                      <tr class="text-racv">
                        <td class="fname">First Name</td>
                        <td class="lname">Last Name</td>
                        <td class="age">Age</td>
                        <td class="dob">Date of Birth</td>
                        <td class="amc">Additional Medical Cover</td>
                        <td class="mc">Medical Conditions</td>
                      </tr>
                      <tr class="fw-bold">
                        <td>{{ dependent.firstName }}</td>
                        <td>{{ dependent.lastName }}</td>
                        <td>{{ dependent.dateOfBirth | ageFormat }}</td>
                        <td>{{ dependent.dateOfBirth }}</td>
                        <td>$ {{ dependent.medicalCover | amountFormat }}</td>
                        <td>
                          {{ dependent.medicalConditions ?? '-' }}
                        </td>
                      </tr>
                    </ng-container>
                  </table>
                </div>
                <div class="d-block d-lg-none">
                  <div class="col-12">
                    <b>Adult 1(Primary Traveller) </b>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <p class="text-racv mb-1">First Name</p>
                      <p class="text-dark fw-semibold">
                        {{ travelSummary.adult1.firstName }}
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="text-racv mb-1">Last Name</p>
                      <p class="text-dark fw-semibold">
                        {{ travelSummary.adult1.lastName }}
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="text-racv mb-1">Age</p>
                      <p class="text-dark fw-semibold">
                        {{ travelSummary.adult1.dateOfBirth | ageFormat }}
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="text-racv mb-1">Date of Birth</p>
                      <p class="text-dark fw-semibold">
                        {{ travelSummary.adult1.dateOfBirth }}
                      </p>
                    </div>

                    <div class="col-12">
                      <p class="text-racv mb-1">Additional Medical Cover</p>
                      <p class="text-dark fw-semibold">
                        $ {{ travelSummary.adult1.medicalCover | amountFormat }}
                      </p>
                    </div>
                    <div class="col-12">
                      <p class="text-racv mb-1">Medical Conditions</p>
                      <p class="text-dark fw-semibold">
                        {{ travelSummary.adult1.medicalConditions ?? '-' }}
                      </p>
                    </div>
                  </div>
                  <ng-container *ngIf="hasAdult2">
                    <hr />
                    <div class="col-12">
                      <b>Adult 2</b>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <p class="text-racv mb-1">First Name</p>
                        <p class="text-dark fw-semibold">
                          {{ travelSummary.adult2.firstName }}
                        </p>
                      </div>
                      <div class="col-4">
                        <p class="text-racv mb-1">Last Name</p>
                        <p class="text-dark fw-semibold">
                          {{ travelSummary.adult2.lastName }}
                        </p>
                      </div>
                      <div class="col-4">
                        <p class="text-racv mb-1">Age</p>
                        <p class="text-dark fw-semibold">
                          {{ travelSummary.adult2.dateOfBirth | ageFormat }}
                        </p>
                      </div>
                      <div class="col-4">
                        <p class="text-racv mb-1">Date of Birth</p>
                        <p class="text-dark fw-semibold">
                          {{ travelSummary.adult2.dateOfBirth }}
                        </p>
                      </div>
                      <div class="col-12">
                        <p class="text-racv mb-1">Additional Medical Cover</p>
                        <p class="text-dark fw-semibold">
                          $
                          {{ travelSummary.adult2.medicalCover | amountFormat }}
                        </p>
                      </div>
                      <div class="col-12">
                        <p class="text-racv mb-1">Medical Conditions</p>
                        <p class="text-dark fw-semibold">
                          {{ travelSummary.adult2.medicalConditions ?? '-' }}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let dependent of dependentsInfo">
                    <hr />
                    <div class="col-12">
                      <b>Dependent 1</b>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <p class="text-racv mb-1">First Name</p>
                        <p class="text-dark fw-semibold">
                          {{ dependent.firstName }}
                        </p>
                      </div>
                      <div class="col-4">
                        <p class="text-racv mb-1">Last Name</p>
                        <p class="text-dark fw-semibold">
                          {{ dependent.lastName }}
                        </p>
                      </div>
                      <div class="col-4">
                        <p class="text-racv mb-1">Age</p>
                        <p class="text-dark fw-semibold">
                          {{ dependent.dateOfBirth | ageFormat }}
                        </p>
                      </div>
                      <div class="col-4">
                        <p class="text-racv mb-1">Date of Birth</p>
                        <p class="text-dark fw-semibold">
                          {{ dependent.dateOfBirth }}
                        </p>
                      </div>
                      <div class="col-12">
                        <p class="text-racv mb-1">Additional Medical Cover</p>
                        <p class="text-dark fw-semibold">
                          $ {{ dependent.medicalCover | amountFormat }}
                        </p>
                      </div>
                      <div class="col-12">
                        <p class="text-racv mb-1">Medical Conditions</p>
                        <p class="text-dark fw-semibold">
                          {{ dependent.medicalConditions ?? '-' }}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="quote selection stepthree">
    <div class="container mt-3 p-4 p-md-3">
      <div class="row justify-content-center">
        <div class="col-md-11 col-12 borderpre p-2 p-md-4">
          <div class="row justify-content-center">
            <div class="col-md-12 p-3">
              <div class="summary-title d-flex justify-content-between">
                <h3>Contact Details of Primary Traveller</h3>
                <h4>
                  <a
                    href="#"
                    class="text-info"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#contact_edit"
                    aria-controls=""
                    (click)="
                      sendAdobeEditButtonClickEvent(
                        'Contact Details of Primary Traveller'
                      )
                    "
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </a>
                </h4>
              </div>
            </div>
            <div class="col-md-12">
              <div class="summary-result bg-light p-md-3 p-2">
                <div class="d-none d-lg-block">
                  <table class="w-100">
                    <!-- Entry Start -->
                    <tr class="text-racv">
                      <td>Email</td>
                      <td>Mobile Number</td>
                      <td style="width: 60%">Address</td>
                      <!-- <td style="width: 30%">Emergency Contact</td> -->
                    </tr>
                    <tr class="fw-bold">
                      <td>{{ contactDetails.email }}</td>
                      <td>{{ contactDetails.phoneNumber }}</td>
                      <td>
                        {{ contactDetails.address }}
                      </td>
                      <!-- <td>
                        {{
                          contactDetails.emergencyContact?.firstName +
                            ' ' +
                            contactDetails.emergencyContact?.lastName
                        }}
                        <br />
                        {{ contactDetails.emergencyContact?.emailId }} <br />
                        {{ contactDetails.emergencyContact?.phoneNumber }}
                      </td> -->
                    </tr>
                  </table>
                </div>

                <div class="d-block d-lg-none">
                  <div class="col 12">
                    <p class="text-racv mb-1">Email</p>
                    <p class="text-dark fw-semibold">
                      {{ contactDetails.email }}
                    </p>
                  </div>
                  <div class="col 12">
                    <p class="text-racv mb-1">Phone</p>
                    <p class="text-dark fw-semibold">
                      {{ contactDetails.phoneNumber }}
                    </p>
                  </div>
                  <div class="col 12">
                    <p class="text-racv mb-1">Address</p>
                    <p class="text-dark fw-semibold">
                      {{ contactDetails.address }}
                    </p>
                  </div>
                  <div class="col 12">
                    <!-- <p class="text-racv mb-1">Emergency Contact</p>
                    <p class="text-dark fw-semibold">
                      {{
                        contactDetails.emergencyContact?.firstName +
                          ' ' +
                          contactDetails.emergencyContact?.lastName
                      }}
                      <br />
                      {{ contactDetails.emergencyContact?.emailId }} <br />
                      {{ contactDetails.emergencyContact?.phoneNumber }}
                    </p> -->
                  </div>
                  <div class="col 12"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="quote selection stepthree">
    <div class="container mt-3 p-md-3 p-4">
      <div class="row justify-content-center">
        <div class="col-md-11 col-12 borderpre p-2 p-md-4">
          <div class="row justify-content-center">
            <div class="col-md-12 p-3">
              <div class="summary-title d-flex justify-content-between">
                <h3>Policy Details</h3>
                <h4>
                  <a
                    routerLink="/quotes-steps-one"
                    class="text-info"
                    (click)="sendAdobeEditButtonClickEvent('Policy Details')"
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </a>
                </h4>
              </div>
            </div>

            <div class="col-md-6">
              <div class="summary-result bg-light p-md-3 p-2">
                <table class="w-100">
                  <tr>
                    <td class="text-racv mobtd">Quote Number</td>
                    <td class="fw-bold">{{ policyDetails.quoteNumber }}</td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">Destination</td>
                    <td class="fw-bold">{{ policyDetails.destination }}</td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">Trip Type</td>
                    <td class="fw-bold">
                      {{
                        policyDetails.tripType === 'Single'
                          ? 'Single Trip'
                          : 'Annual Multi Trip'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">Travel Dates</td>
                    <td class="fw-bold">
                      {{ policyDetails.depart | dateFormat : 'DD/MM/YYYY' }}
                      <span>
                        <img src="assets/images/racv/chevron_right.png" />
                      </span>
                      {{ policyDetails.return | dateFormat : 'DD/MM/YYYY' }}
                      <span
                        *ngIf="policyDetails?.isSingleTrip"
                        class="text-gray ms-1"
                        >{{ policyDetails.duration }} days</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">Cruise</td>
                    <td class="fw-bold">
                      {{ policyDetails.isCruise }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">Ski/Winter Sports</td>
                    <td class="fw-bold">{{ policyDetails.isSki }}</td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">Age of Travellers</td>
                    <td class="fw-bold">{{ policyDetails.ages }}</td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">No. Dependents</td>
                    <td class="fw-bold">{{ policyDetails.dependentsCount }}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="summary-result bg-light p-md-3 p-2">
                <table class="w-100">
                  <tr>
                    <td class="text-racv mobtd">
                      {{ policyDetails.productAlias }} Policy
                    </td>
                    <td class="fw-bold">
                      $ {{ policyDetails.totalCoverPolicy | number : '1.2-2' }}
                    </td>
                  </tr>
                  <tr *ngIf="!policyDetails.isSingleTrip">
                    <td class="text-racv mobtd">
                      {{ storedPriceDetails?.selectedPrice?.multiTripLength }}
                      Max Length Trip
                    </td>
                    <td class="fw-bold">
                      $
                      {{
                        storedPriceDetails?.selectedPrice
                          ?.multiTripLengthPremium | number : '1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">
                      {{ policyDetails.excessLevel }} Policy Excess
                    </td>
                    <td class="fw-bold">
                      $ {{ policyDetails.excess | number : '1.2-2' }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">Membership Discount</td>
                    <td
                      class="fw-bold"
                      *ngIf="policyDetails.membershipDiscount < 0"
                    >
                      - ${{
                        -policyDetails.membershipDiscount | number : '1.2-2'
                      }}
                    </td>
                    <td
                      class="fw-bold"
                      *ngIf="policyDetails.membershipDiscount === 0"
                    >
                      $ 0.00
                    </td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">Cruise</td>
                    <td class="fw-bold">
                      $ {{ policyDetails.cruiseExpense | number : '1.2-2' }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">Ski/Winter Sports</td>
                    <td class="fw-bold">
                      $ {{ policyDetails.skiExpense | number : '1.2-2' }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">Medical Conditions</td>
                    <td class="fw-bold">
                      $ {{ policyDetails.additionalPremium | number : '1.2-2' }}
                    </td>
                  </tr>
                  <tr *ngIf="policyDetails.promoCodeDisc < 0">
                    <td class="text-racv mobtd">Promo Discount</td>
                    <td class="fw-bold">
                      <span class="text-danger"
                        >-$
                        {{
                          -policyDetails.promoCodeDisc | number : '1.2-2'
                        }}</span
                      >
                      <span class="promo"
                        >Promo Code: {{ policyDetails.promoCode }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">GST</td>
                    <td class="fw-bold">
                      $ {{ policyDetails.gst | number : '1.2-2' }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-racv mobtd">Stamp Duty</td>
                    <td class="fw-bold">
                      $ {{ policyDetails.stampDuty | number : '1.2-2' }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="col-md-6">&nbsp;</div>
            <div class="col-md-6">
              <div class="sub-total py-3">
                <h3 class="">
                  Sub Total:
                  <b>${{ policyDetails.totalAmount | number : '1.2-2' }}</b>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div [formGroup]="disclaimerMarketingForm">
    <section class="quote selection stepthree">
      <div class="container mt-3 p-md-3 p-4">
        <div class="row justify-content-center">
          <div class="col-md-11 col-12 borderpre p-3 pregraybg">
            <div class="row justify-content-center">
              <div class="col-md-12 p-3">
                <div class="row justify-content-start">
                  <div class="col-10 myflexend">
                    <h3>Disclaimer</h3>
                  </div>
                  <div class="col-10">
                    <p>
                      I have read and understood the
                      <a href="/privacy-policy" target="_blank"
                        >Privacy Policies</a
                      >
                      and the information contained in the
                      <a href="{{ pdsLink }}" target="_blank"
                        >Combined Product Disclosure Statement and Financial
                        Services Guide (PDS) </a
                      >which includes:
                    </p>
                    <ul class="ps-5">
                      <li>Your Duty not to make a misrepresentation; and</li>
                      <li>
                        I am purchasing this policy before I have started my
                        trip; and
                      </li>
                      <li>
                        Other important detail outlined under Who can purchase
                        this policy, including Citizenship and residency status.
                      </li>
                    </ul>
                    <p>
                      <strong
                        >Do you agree to the privacy declaration and terms and
                        conditions as outlined in PDS?</strong
                      >
                    </p>

                    <input
                      type="radio"
                      class="btn-check"
                      name="privacy"
                      id="privacy_yes"
                      autocomplete="off"
                      value="yes"
                      formControlName="privacy"
                      (change)="onDiscalimerSelection(true)"
                      (click)="
                        triggerSegmentEvent('disclaimerYes');
                        sendAdobeIneractionEvent('disclaimerYes')
                      "
                    />
                    <label
                      class="btn btn-outline-info btn-lg szbtn"
                      for="privacy_yes"
                      >Yes</label
                    >
                    <input
                      type="radio"
                      class="btn-check"
                      name="privacy"
                      id="privacy_no"
                      autocomplete="off"
                      value="no"
                      formControlName="privacy"
                      (click)="onDiscalimerSelection(false)"
                      (click)="
                        triggerSegmentEvent('disclaimerNo');
                        sendAdobeIneractionEvent('disclaimerNo')
                      "
                    />
                    <label
                      type="button"
                      class="btn btn-outline-info btn-lg szbtn"
                      for="privacy_no"
                      >No</label
                    >
                    <ng-container
                      *ngIf="
                        showDisclaimerMessage ||
                        ((disclaimerMarketingForm.get('privacy')?.invalid &&
                          (disclaimerMarketingForm.get('privacy')?.dirty ||
                            disclaimerMarketingForm.get('privacy')?.touched)) && disclaimerMarketingForm.get('privacy')?.errors?.['required'])
                      "
                    >
                      <div>
                        <small class="text-danger">
                          <span>
                            You cannot proceed without agreeing to the Privacy
                            Declaration and the PDS terms and conditions
                          </span>
                        </small>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="quote selection stepthree">
      <div class="container mt-3 p-md-3 p-4">
        <div class="row justify-content-center">
          <div class="col-md-11 col-12 borderpre p-3 pregraybg">
            <div class="row justify-content-center">
              <div class="col-md-12 p-3">
                <div class="row justify-content-start">
                  <div class="col-10 myflexend">
                    <h3>Marketing Consent</h3>
                  </div>
                  <div class="col-10">
                    <p>
                      RACV would like to use your personal information to keep
                      you up to date on the latest RACV product offers, benefits
                      and services. Your personal information will be subject to
                      the
                      <a
                        href="https://www.racv.com.au/about-racv/corporate-governance/legal.html"
                        target="_blank"
                        >RACV Privacy Charter.</a
                      >
                    </p>
                    <div class="d-flex">
                      <input
                        type="checkbox"
                        style="width: 20px"
                        name="marketing"
                        id="marketing_checkbox"
                        formControlName="marketing"
                        (click)="
                          triggerSegmentEvent('marketingConsent', $event);
                          sendAdobeIneractionEvent('marketingConsent')
                        "
                      />
                      <label for="marketing_checkbox" style="margin-left: 10px">
                        I agree to the above conditions and consent to being
                        contacted. I understand I can withdraw my consent at any
                        time.</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <section class="quote selection stepthree">
    <div class="container mt-3 p-3">
      <div class="row justify-content-center">
        <div class="d-md-none d-flex col-12 mb-2 justify-content-center">
          <a routerLink="/quotes-steps-two" class="btn btn-outline-info btn">
            <i class="fa-solid fa-chevron-left"></i>
          </a>
          <button
            class="btn btn-info btn cpp"
            (click)="confirmProceedPayment()"
            [disabled]="
              proceedBtnDisabled || primaryReassessment || secondaryReassessment
            "
          >
            Confirm & Proceed to Payment
          </button>
        </div>
        <div class="col-md-12 justify-content-between d-flex flexgrid">
          <div class="col-12 col-md-6 mb-2">
            <a
              class="button btn btn-outline-info p-1 px-2 save-quote mb-2"
              (click)="
                openSaveAndSendModal();
                sendAdobeButtonBarClickEvent('Save & Email Quote')
              "
              tabindex="0"
              ><span>Save & Email Quote</span>
              <!-- <i class="fa-regular fa-envelope"></i> -->
              <img src="assets/icons/racv/envelope.svg" alt="" width="24" />
            </a>
            <a
              (click)="
                downloadPDS(); sendAdobeSideBarClickEvent('Download PDS')
              "
              class="button btn btn-outline-info p-1 px-2 download-quote mb-2"
              tabindex="0"
              ><span id="downloadPDSButton">Download PDS</span>
              <!-- <i class="fa-regular fa-envelope"></i> -->
              <img src="assets/icons/racv/download.svg" alt="" width="24" />
            </a>

            <a
              class="btn btn-outline-info px-md-1 px-2 mb-2 dlsvbtn mobhalf"
              (click)="
                openSaveAndSendModal();
                sendAdobeButtonBarClickEvent('Save & Email Quote')
              "
              tabindex="0"
              >Save & Email Quote</a
            >
            <a
              (click)="
                downloadPDS(); sendAdobeButtonBarClickEvent('Download PDS')
              "
              class="btn btn-outline-info px-md-1 px-2 mb-2 dlsvbtn mobhalf dpds"
              tabindex="0"
              >Download PDS</a
            >
          </div>
          <div class="col-12 col-md-6" style="text-align: right">
            <div class="d-md-block d-none">
              <a
                routerLink="/quotes-steps-two"
                class="btn btn-outline-info btn btn-lg"
                (click)="sendAdobeButtonBarClickEvent('Back')"
              >
                <i class="fa-solid fa-chevron-left"></i> Back
              </a>
              <button
                class="btn btn-info btn btn-lg"
                (click)="
                  confirmProceedPayment();
                  sendAdobeButtonBarClickEvent('Confirm & Proceed to Payment')
                "
                [disabled]="proceedBtnDisabled"
              >
                Confirm & Proceed to Payment
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <p class="text-center my-4" *ngIf="autoClub === 'RACV'">
          <strong>Need help? Call us on:</strong>
          <strong class="number text-md-end">
            <a
              class="text-primary text-decoration-underline"
              ref="tel:13 13 29"
              (click)="sendAdobeNeedCallClickEvent()"
            >
              13 13 29</a
            >
          </strong>
        </p>
      </div>
    </div>
  </section>
  <app-email-quote #saveAndSendEmail></app-email-quote>
  <!-- Edit Canvas for Traveller summery -->
  <form [formGroup]="travelerSummaryForm">
    <!-- <form [formGroup]="travelerSummaryForm" (ngSubmit)="onUpdateTravelSummary($event)"> -->
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="traveller_summery_edit"
      aria-labelledby=""
      style="width: 60%"
    >
      <div class="offcanvas-header mb-0 border-bottom">
        <h5 class="offcanvas-title" id="offcanvasRightLabel">
          <b>Traveller Summary </b>
        </h5>
        <a data-bs-dismiss="offcanvas" aria-label="Close"
          ><img
            src="assets/icons/racv/cancel.png"
            (click)="onCancelUpdateTravelSummary()"
        /></a>
      </div>

      <div class="offcanvas-body">
        <div
          class="lightcbg justify-content-center align-items-center rounded my-2 p-2 pt-3 pb-3 tsummeryedit"
        >
          <img src="assets/images/racv/tooltipcolor.png" class="tooltipc" />
          <small class="smalltext">
            At this stage, only the First Name and Last Name fields can be
            edited. Updating the Date of Birth or Medical Conditions may affect
            pricing. Please click the "Edit All" button to return to the details
            screen to modify all fields.
          </small>
          <a routerLink="/quotes-steps-two">
            <button class="btn btn-info adjustablewidth">edit all</button>
          </a>
        </div>

        <div class="traveller-edit-section">
          <div class="form-floating">
            <b>Adult 1(Primary Traveller)</b>
          </div>
          <div formGroupName="adult1">
            <div class="row g-1 mb-2">
              <div class="col-sm form-floating">
                <input
                  type="text"
                  pInputText
                  class="form-control"
                  formControlName="firstName"
                  id="adult_1_firstName"
                  placeholder="Adult 1 Name"
                  (blur)="
                    onBlurNameField(travelerSummaryForm, 'adult1.firstName')
                  "
                />
                <label for="adult_1_firstName">Adult 1 First Name</label>
                <ng-container
                  *ngIf="
                    travelerSummaryForm.get('adult1.firstName')?.invalid &&
                    (travelerSummaryForm.get('adult1.firstName')?.dirty ||
                      travelerSummaryForm.get('adult1.firstName')?.touched)
                  "
                >
                  <small class="text-danger">
                    <span
                      *ngIf="travelerSummaryForm.get('adult1.firstName')?.errors?.['required']"
                    >
                      {{ errorMessages['first_name']?.['required'] }}
                    </span>
                    <span
                      *ngIf="travelerSummaryForm.get('adult1.firstName')?.errors?.['minlength']"
                    >
                      {{ errorMessages['first_name']?.['minLength'] }}
                    </span>
                    <span
                      *ngIf="travelerSummaryForm.get('adult1.firstName')?.errors?.['nameValidationMessage']"
                    >
                      {{ travelerSummaryForm.get('adult1.firstName')?.errors?.['nameValidationMessage'] }}
                      <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                    </span>
                  </small>
                </ng-container>
              </div>
              <div class="col-sm form-floating">
                <input
                  type="text"
                  pInputText
                  class="form-control"
                  formControlName="lastName"
                  id="adult_1_lastName"
                  placeholder="Adult 1 Name"
                  (blur)="
                    onBlurNameField(travelerSummaryForm, 'adult1.lastName')
                  "
                />
                <label for="adult_1_lastName">Adult 1 Last Name</label>
                <ng-container
                  *ngIf="
                    travelerSummaryForm.get('adult1.lastName')?.invalid &&
                    (travelerSummaryForm.get('adult1.lastName')?.dirty ||
                      travelerSummaryForm.get('adult1.lastName')?.touched)
                  "
                >
                  <small class="text-danger">
                    <span
                      *ngIf="travelerSummaryForm.get('adult1.lastName')?.errors?.['required']"
                    >
                      {{ errorMessages['last_name']?.['required'] }}
                    </span>
                    <span
                      *ngIf="travelerSummaryForm.get('adult1.lastName')?.errors?.['minlength']"
                    >
                      {{ errorMessages['last_name']?.['minLength'] }}
                    </span>
                    <span
                      *ngIf="travelerSummaryForm.get('adult1.lastName')?.errors?.['nameValidationMessage']"
                    >
                      {{ travelerSummaryForm.get('adult1.lastName')?.errors?.['nameValidationMessage'] }}
                      <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                    </span>
                  </small>
                </ng-container>
              </div>
              <div class="col-sm form-floating">
                <input
                  type="text"
                  pInputText
                  class="form-control"
                  formControlName="age"
                  id="adult_1_age"
                  placeholder=""
                />
                <label for="adult_1_age">Age</label>
              </div>
              <div class="col-sm form-floating">
                <input
                  type="text"
                  class="form-control"
                  formControlName="dateOfBirth"
                  id="adult_1_dob"
                  placeholder=""
                />
                <label for="adult_1_dob">Date of Birth</label>
                <ng-container
                  *ngIf="
                    travelerSummaryForm.get('adult1.dateOfBirth')?.invalid &&
                    (travelerSummaryForm.get('adult1.dateOfBirth')?.dirty ||
                      travelerSummaryForm.get('adult1.dateOfBirth')?.touched)
                  "
                >
                  <small class="text-danger">
                    <span
                      *ngIf="travelerSummaryForm.get('adult1.dateOfBirth')?.errors?.['required']"
                    >
                      {{ errorMessages['dob']?.['required'] }}
                    </span>
                  </small>
                </ng-container>
              </div>
            </div>
            <div class="row g-1 mb-2">
              <div class="col-sm form-floating">
                <input
                  type="text"
                  pInputText
                  class="form-control"
                  formControlName="medicalCover"
                  id="adult_1_medicalCover"
                  placeholder=""
                />
                <label for="adult_1_medicalCover"
                  >Additional Medical Cover</label
                >
              </div>
              <div class="col form-floating">
                <input
                  type="text"
                  pInputText
                  class="form-control"
                  id="adult_1_medicalCondition"
                  formControlName="medicalConditions"
                  placeholder=""
                />
                <label for="adult_1_medicalCondition">Medical Conditions</label>
              </div>
            </div>
          </div>
          <!--  -->
          <ng-container *ngIf="hasAdult2">
            <div><b>Adult 2</b></div>
            <div formGroupName="adult2">
              <div class="row g-1 mb-2">
                <div class="col-sm form-floating">
                  <input
                    type="text"
                    pInputText
                    class="form-control"
                    formControlName="firstName"
                    id="adult_2_firstName"
                    placeholder="Adult 2 Name"
                    (blur)="
                      onBlurNameField(travelerSummaryForm, 'adult2.firstName')
                    "
                  />
                  <label for="adult_2_firstName">Adult 2 First Name</label>
                  <ng-container
                    *ngIf="
                      travelerSummaryForm.get('adult2.firstName')?.invalid &&
                      (travelerSummaryForm.get('adult2.firstName')?.dirty ||
                        travelerSummaryForm.get('adult2.firstName')?.touched)
                    "
                  >
                    <small class="text-danger">
                      <span
                        *ngIf="travelerSummaryForm.get('adult2.firstName')?.errors?.['required']"
                      >
                        {{ errorMessages['first_name']?.['required'] }}
                      </span>
                      <span
                        *ngIf="travelerSummaryForm.get('adult2.firstName')?.errors?.['minlength']"
                      >
                        {{ errorMessages['first_name']?.['minLength'] }}
                      </span>
                      <span
                        *ngIf="travelerSummaryForm.get('adult2.firstName')?.errors?.['nameValidationMessage']"
                      >
                        {{ travelerSummaryForm.get('adult2.firstName')?.errors?.['nameValidationMessage'] }}
                        <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                      </span>
                    </small>
                  </ng-container>
                </div>
                <div class="col-sm form-floating">
                  <input
                    type="text"
                    pInputText
                    class="form-control"
                    formControlName="lastName"
                    id="adult_2_lastName"
                    placeholder="Adult 2 Name"
                    (blur)="
                      onBlurNameField(travelerSummaryForm, 'adult2.lastName')
                    "
                  />
                  <label for="adult_2_lastName">Adult 2 Last Name</label>
                  <ng-container
                    *ngIf="
                      travelerSummaryForm.get('adult2.lastName')?.invalid &&
                      (travelerSummaryForm.get('adult2.lastName')?.dirty ||
                        travelerSummaryForm.get('adult2.lastName')?.touched)
                    "
                  >
                    <small class="text-danger">
                      <span
                        *ngIf="travelerSummaryForm.get('adult2.lastName')?.errors?.['required']"
                      >
                        {{ errorMessages['last_name']?.['required'] }}
                      </span>
                      <span
                        *ngIf="travelerSummaryForm.get('adult2.lastName')?.errors?.['minlength']"
                      >
                        {{ errorMessages['last_name']?.['minLength'] }}
                      </span>
                      <span
                        *ngIf="travelerSummaryForm.get('adult2.lastName')?.errors?.['nameValidationMessage']"
                      >
                        {{ travelerSummaryForm.get('adult2.lastName')?.errors?.['nameValidationMessage'] }}
                        <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                      </span>
                    </small>
                  </ng-container>
                </div>
                <div class="col-sm form-floating">
                  <input
                    type="text"
                    pInputText
                    class="form-control"
                    formControlName="age"
                    id="adult_2_age"
                    placeholder=""
                  />
                  <label for="adult_2_age">Age</label>
                </div>
                <div class="col-sm form-floating">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="dateOfBirth"
                    id="adult_2_dob"
                    placeholder=""
                  />
                  <label for="adult_2_dob">Date of Birth</label>
                  <ng-container
                    *ngIf="
                      travelerSummaryForm.get('adult2.dateOfBirth')?.invalid &&
                      (travelerSummaryForm.get('adult2.dateOfBirth')?.dirty ||
                        travelerSummaryForm.get('adult2.dateOfBirth')?.touched)
                    "
                  >
                    <small class="text-danger">
                      <span
                        *ngIf="travelerSummaryForm.get('adult2.dateOfBirth')?.errors?.['required']"
                      >
                        {{ errorMessages['dob']?.['required'] }}
                      </span>
                    </small>
                  </ng-container>
                </div>
              </div>
              <div class="row g-1 mb-2">
                <div class="col-sm form-floating">
                  <input
                    type="text"
                    pInputText
                    class="form-control"
                    formControlName="medicalCover"
                    id="adult_2_medicalCover"
                    placeholder=""
                  />
                  <label for="adult_2_medicalCover"
                    >Additional Medical Cover</label
                  >
                </div>
                <div class="col form-floating">
                  <input
                    type="text"
                    pInputText
                    class="form-control"
                    id="adult_2_medicalCondition"
                    formControlName="medicalConditions"
                    placeholder=""
                  />
                  <label for="adult_2_medicalCondition"
                    >Medical Conditions</label
                  >
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="dependents.controls.length > 0">
            <div class="" formArrayName="dependents">
              <ng-container
                *ngFor="let dependent of dependents.controls; let i = index"
                [formGroupName]="i"
              >
                <div>
                  <b>Dependent {{ i + 1 }}</b>
                </div>
                <div>
                  <div class="row g-1 mb-2">
                    <div class="col-sm form-floating">
                      <input
                        type="text"
                        pInputText
                        class="form-control"
                        formControlName="firstName"
                        id="dependents_firstName"
                        placeholder="First Name"
                        (blur)="onBlurNameField(dependent, 'firstName')"
                      />
                      <label for="dependents_firstName">First Name</label>
                      <ng-container
                        *ngIf="
                          dependent.get('firstName')?.invalid &&
                          (dependent.get('firstName')?.dirty ||
                            dependent.get('firstName')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="dependent.get('firstName')?.errors?.['required']"
                          >
                            {{ errorMessages['first_name']?.['required'] }}
                          </span>
                          <span
                            *ngIf="dependent.get('firstName')?.errors?.['minlength']"
                          >
                            {{ errorMessages['first_name']?.['minLength'] }}
                          </span>
                          <span
                            *ngIf="dependent.get('firstName')?.errors?.['nameValidationMessage']"
                          >
                            {{ dependent.get('firstName')?.errors?.['nameValidationMessage'] }}
                            <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                          </span>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-sm form-floating">
                      <input
                        type="text"
                        pInputText
                        class="form-control"
                        formControlName="lastName"
                        id="dependents_lastName"
                        placeholder="Last Name"
                        (blur)="onBlurNameField(dependent, 'lastName')"
                      />
                      <label for="dependents_lastName">Last Name</label>
                      <ng-container
                        *ngIf="
                          dependent.get('lastName')?.invalid &&
                          (dependent.get('lastName')?.dirty ||
                            dependent.get('lastName')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="dependent.get('lastName')?.errors?.['required']"
                          >
                            {{ errorMessages['last_name']?.['required'] }}
                          </span>
                          <span
                            *ngIf="dependent.get('lastName')?.errors?.['minlength']"
                          >
                            {{ errorMessages['last_name']?.['minLength'] }}
                          </span>
                          <span
                            *ngIf="dependent.get('lastName')?.errors?.['nameValidationMessage']"
                          >
                            {{ dependent.get('lastName')?.errors?.['nameValidationMessage'] }}
                            <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                          </span>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-sm form-floating">
                      <input
                        type="text"
                        pInputText
                        class="form-control"
                        id="dependent_1_age"
                        formControlName="age"
                        placeholder=""
                      />
                      <label for="dependent_1_age">Age</label>
                    </div>
                    <div class="col-sm form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="dependent_1_dob"
                        formControlName="dateOfBirth"
                        placeholder=""
                      />
                      <label for="dependent_1_dob">Date of Birth</label>
                    </div>
                  </div>
                  <div class="row g-1 mb-2">
                    <div class="col-sm form-floating">
                      <input
                        type="text"
                        pInputText
                        class="form-control"
                        id="dependent_1_medicalCover"
                        formControlName="medicalCover"
                      />
                      <label for="dependent_1_medicalCover"
                        >Additional Medical Cover</label
                      >
                    </div>
                    <div class="col form-floating">
                      <input
                        type="text"
                        pInputText
                        class="form-control"
                        id="dependent_1_medicalCondition"
                        formControlName="medicalConditions"
                      />
                      <label for="dependent_1_medicalCondition"
                        >Medical Conditions</label
                      >
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container
            *ngIf="travelerSummaryForm?.errors?.['sameDetailsError']"
          >
            <small class="text-danger">
              <span>
                {{errorMessages['sameDetails']?.['required']}}
              </span>
            </small>
          </ng-container>
          <div class="">
            <button
              class="btn btn-outline-danger px-4 mobhalf"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              (click)="onCancelUpdateTravelSummary()"
            >
              Cancel
            </button>
            <button
              class="btn btn-info px-4 mobhalf"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              (click)="onUpdateTravelSummary($event)"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- Edit Canvas for Traveller summery -->
  <form [formGroup]="contactDetailsForm">
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="contact_edit"
      aria-labelledby=""
      style="width: 50%"
    >
      <div class="offcanvas-header mb-0 border-bottom">
        <h5 class="offcanvas-title" id="offcanvasRightLabel">
          <b>Contact Details of Primary Traveller </b>
        </h5>
        <a data-bs-dismiss="offcanvas" aria-label="Close"
          ><img
            src="assets/icons/racv/cancel.png"
            (click)="onCancelUpdateContactDetails()"
        /></a>
      </div>
      <div class="offcanvas-body">
        <div
          class="lightcbg justify-content-center align-items-center rounded my-2 p-2 pt-3 pb-3 tsummeryedit"
        >
          <img src="assets/images/racv/tooltipcolor.png" class="tooltipc" />

          <small class="smalltext">
            At this stage, only the Email and Mobile fields can be edited.
            Updating the Address may affect pricing. Please click the "Edit All"
            button to return to the details screen to modify all fields.
          </small>

          <a routerLink="/quotes-steps-two">
            <button class="btn btn-info adjustablewidth">edit all</button>
          </a>
        </div>
        <div class="traveller-edit-section">
          <div class="form-floating mb-3">
            <input
              type="email"
              pInputText
              class="form-control"
              id="contact_email"
              placeholder="Email address"
              formControlName="email"
            />
            <label for="contact_email">Email address</label>
            <ng-container
              *ngIf="
                contactDetailsForm.get('email')?.invalid &&
                (contactDetailsForm.get('email')?.dirty ||
                  contactDetailsForm.get('email')?.touched)
              "
            >
              <small class="text-danger">
                <span
                  *ngIf="contactDetailsForm.get('email')?.errors?.['required']"
                >
                  {{ errorMessages['email_id']?.['required'] }}
                </span>
                <span
                  *ngIf="contactDetailsForm.get('email')?.errors?.['email']"
                >
                  {{ errorMessages['email_id']?.['email'] }}
                </span>
              </small>
            </ng-container>
          </div>
          <div class="form-floating mb-3">
            <input
              class="form-control"
              id="contact_phone"
              placeholder="Mobile Number"
              formControlName="phoneNumber"
              type="tel"
              maxlength="10"
              pKeyFilter="int"
              (blur)="onBlurMobileNumber()"
            />
            <label for="contact_phone">Mobile Number </label>
            <ng-container
              *ngIf="
                contactDetailsForm.get('phoneNumber')?.invalid &&
                (contactDetailsForm.get('phoneNumber')?.dirty ||
                  contactDetailsForm.get('phoneNumber')?.touched)
              "
            >
              <small class="text-danger">
                <span
                  *ngIf="contactDetailsForm.get('phoneNumber')?.errors?.['required']"
                >
                  {{ errorMessages['phone_number']?.['required'] }}
                </span>
                <span
                  *ngIf="contactDetailsForm.get('phoneNumber')?.errors?.['phoneNumberInvalid']"
                >
                  {{ errorMessages['phone_number']?.['phoneNumberInvalid'] }}
                </span>
              </small>
            </ng-container>
          </div>

          <div class="form-floating mb-3">
            <textarea
              readonly
              class="form-control"
              placeholder="Address"
              id="contact_address"
              style="min-height: 100px"
              formControlName="address"
              >{{ editContactDetails.address }}
          </textarea
            >
            <label for="contact_address">Address</label>
          </div>
          <div formGroupName="emergencyContact">
            <!-- <label>Emergency Contact</label>
            <div class="row col-12">
              <div class="col-md-6 col-12">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    pInputText
                    class="form-control"
                    id="emergency_firstName"
                    placeholder="First Name"
                    formControlName="firstName"
                  />
                  <label for="emergency_firstName">First Name</label>
                  <ng-container
                    *ngIf="
                      contactDetailsForm.get('emergencyContact.firstName')
                        ?.invalid &&
                      (contactDetailsForm.get('emergencyContact.firstName')
                        ?.dirty ||
                        contactDetailsForm.get('emergencyContact.firstName')
                          ?.touched)
                    "
                  >
                    <small class="text-danger">
                      <span
                        *ngIf="contactDetailsForm.get('emergencyContact.firstName')?.errors?.['required']"
                      >
                        {{ errorMessages['first_name']?.['required'] }}
                      </span>
                      <span
                        *ngIf="contactDetailsForm.get('emergencyContact.firstName')?.errors?.['minlength']"
                      >
                        {{ errorMessages['first_name']?.['minLength'] }}
                      </span>
                    </small>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    pInputText
                    class="form-control"
                    id="emergency_lastName"
                    placeholder="Last Name"
                    formControlName="lastName"
                  />
                  <label for="emergency_lastName">Last Name</label>
                  <ng-container
                    *ngIf="
                      contactDetailsForm.get('emergencyContact.lastName')
                        ?.invalid &&
                      (contactDetailsForm.get('emergencyContact.lastName')
                        ?.dirty ||
                        contactDetailsForm.get('emergencyContact.lastName')
                          ?.touched)
                    "
                  >
                    <small class="text-danger">
                      <span
                        *ngIf="contactDetailsForm.get('emergencyContact.lastName')?.errors?.['required']"
                      >
                        {{ errorMessages['last_name']?.['required'] }}
                      </span>
                      <span
                        *ngIf="contactDetailsForm.get('emergencyContact.lastName')?.errors?.['minlength']"
                      >
                        {{ errorMessages['last_name']?.['minlength'] }}
                      </span>
                    </small>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="row col-12">
              <div class="col-md-6 col-12">
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    pInputText
                    class="form-control"
                    id="emergency_email"
                    placeholder="Email"
                    formControlName="emailId"
                  />
                  <label for="emergency_email">Email</label>
                  <ng-container
                    *ngIf="
                      contactDetailsForm.get('emergencyContact.emailId')
                        ?.invalid &&
                      (contactDetailsForm.get('emergencyContact.emailId')
                        ?.dirty ||
                        contactDetailsForm.get('emergencyContact.emailId')
                          ?.touched)
                    "
                  >
                    <small class="text-danger">
                      <span
                        *ngIf="contactDetailsForm.get('emergencyContact.emailId')?.errors?.['required']"
                      >
                        {{ errorMessages['email_id']?.['required'] }}
                      </span>
                      <span
                        *ngIf="contactDetailsForm.get('emergencyContact.emailId')?.errors?.['email']"
                      >
                        {{ errorMessages['email_id']?.['email'] }}
                      </span>
                    </small>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    pInputText
                    class="form-control"
                    id="emergency_phone"
                    placeholder="Phone"
                    formControlName="phoneNumber"
                    pKeyFilter="int"
                    maxlength="20"
                  />
                  <label for="emergency_phone">Phone</label>
                  <ng-container
                    *ngIf="
                      contactDetailsForm.get('emergencyContact.phoneNumber')
                        ?.invalid &&
                      (contactDetailsForm.get('emergencyContact.phoneNumber')
                        ?.dirty ||
                        contactDetailsForm.get('emergencyContact.phoneNumber')
                          ?.touched)
                    "
                  >
                    <small class="text-danger">
                      <span
                        *ngIf="contactDetailsForm.get('emergencyContact.phoneNumber')?.errors?.['maxlength']"
                      >
                        {{ errorMessages['emergency_phone']?.['maxLength'] }}
                      </span>
                    </small>
                  </ng-container>
                </div>
              </div>
            </div> -->
          </div>

          <div class="">
            <button
              class="btn btn-outline-danger px-4 mobhalf"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              (click)="onCancelUpdateContactDetails()"
            >
              Cancel
            </button>
            <button
              class="btn btn-info px-4 mobhalf"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              (click)="onUpdateContactDetails($event)"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <p-dialog
    header="Recalculation of the medical premium"
    [modal]="true"
    [(visible)]="showReassessmentModal"
    [style]="{ width: '25rem' }"
  >
    <span class="p-text-secondary block mb-5"
      >The change made in your policy has resulted in the recalculation of the
      medical premium. Please click
      <a routerLink="/quotes-steps-two">here</a> to continue.</span
    >
  </p-dialog>

  <app-racv-footer></app-racv-footer>
</div>
